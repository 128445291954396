import {Row, Col} from "react-bootstrap";
import { currencyFormatter, getDateFromDayinYear } from "utils/helpers";

import lastDeposit from "images/svg/income/last-deposit.svg";
import lastWithdrawal from "images/svg/income/last-withdrawal.svg";
import highestBalance from "images/svg/income/highest-balance.svg";
import lowestBalance from "images/svg/income/lowest-balance.svg";
import averageBalance from "images/svg/income/average-balance.svg";
import highestBand from "images/svg/income/highest-band.svg";
import spike from "images/svg/income/spike.svg";

export default function CashFlowHighlight({data, forYear}){
    const firstMonth = Object.entries(forYear)?.reverse()?.[0]?.[0];
    const getYear = firstMonth.substring(firstMonth.length-4, firstMonth.length)

    return(
        <div className="mt-[40px]">
            <Row>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Maximum Deposit Week</p>
                            <img src={lastDeposit} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">Week {data?.max_depo_week_list_dict?.[0] || 'not available'}</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Maximum Balance Week</p>
                            <img src={lastDeposit} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">Week {data?.max_bal_week_list_dict?.[0] || 'not available'}</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Minimum Balance Week</p>
                            <img src={lowestBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">Week {data?.min_bal_week_list_dict?.[0] || 'not available'}</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Maximum Deposit Day</p>
                            <img src={lastWithdrawal} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{getDateFromDayinYear(getYear, data?.max_depo_day_list_dict?.[0] || 0)}</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Maximum Balance Day</p>
                            <img src={highestBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{getDateFromDayinYear(getYear, data?.max_bal_day_list_dict?.[0] || 0)}</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Minimum Balance Day</p>
                            <img src={lowestBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{getDateFromDayinYear(getYear, data?.min_bal_days_list_dict?.[0] || 0)}</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Average Daily Balance</p>
                            <img src={averageBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{currencyFormatter(data?.averageDailyBal/100 || 0)}</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Percentage Credit on Transactions</p>
                            <img src={highestBand} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{Math.round(data?.perCredTrans || 0)}%</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Percentage Debit on Transactions</p>
                            <img src={spike} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{Math.round(data?.perDebTrans || 0)}%</p>
                    </div>
                </Col>
                <Col md={6} lg={4} xl={4}>
                    <div className="credit_boxes-single">
                        <div className="credit_boxes-single-top">
                            <p>Number Of Negative Balance  (Days)</p>
                            <img src={lowestBalance} alt="icon" className="credit_boxes-single-patternIcon" />
                        </div>
                        <p className="credit_boxes-single-patternText">{data?.negBalDays || 0}</p>
                    </div>
                </Col>
            </Row>
        </div>
    )
}