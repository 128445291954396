import { SelectField } from "components/Form/SelectField";
import { TextField } from "components/Form/TextField";
import Button from "components/shared/Button";
import { FormikProvider, useFormik } from "formik";
import logo from "images/logos/white-logo.svg";
import { useEffect, useState } from "react";
import { IncomeService } from "services/income.service";
import { handleRequestErrors } from "utils/helpers";
import { useSearchParams } from "react-router-dom";
import OTPInput from "otp-input-react";
import { Modal } from "react-bootstrap";
import clsx from "clsx";
import { MdClear } from "react-icons/md";
import { toast } from "react-toastify";
import { initializeOpenBankingSchema } from "utils/validationSchemas";
import { RecovaService } from "services/recova.service";
import pendingIcon from "images/svg/income/pending-approval.svg";


export default function OpenBanking () {
    const [banks, setBanks] = useState([])
    const [searchParams] = useSearchParams();
    // let history = useNavigate();
    const [loading, setLoading] = useState({
        initialize: false,
        verification: false,
    })
    const [initializeResponse, setInitializeResponse] = useState(null)
    const [showOTPModal, setShowOTPModal] = useState(false)
    const [OTP, setOTP] = useState("");
    const [vfd_banks, setVfd_banks] = useState([])
    const [nameField, setNameField] = useState({
        disabled: false,
        loaded: false,
    })
    const [OTPView, setOTPView] = useState(true)

    useEffect(()=>{
        getOpenBanks();
        getNipBanks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getOpenBanks = () => {
        IncomeService.getOpenBankingBankList()
            .then((response) => response.data)
            .then((res) => {
                if(res.status==="success") {
                    setBanks(res.data)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            })
    }

    const getNipBanks = () => {
        RecovaService.getMicrodepositBanklist()
            .then((response) => response.data)
            .then((res) => {
                if(res.status===true){
                    setVfd_banks(res?.data ?? [])
                }
            })
            .catch((err) => {})
    }

    const form = useFormik({
        initialValues: {
            account_number: '',
            account_type: "",
            bank: null,
            duration:"",
            account_name: ""
        },
        validationSchema: initializeOpenBankingSchema, 
        onSubmit: (values) => {
            handleInitialize(values)
        }
    })

    const handleInitialize = (values) => {
        let payload = JSON.stringify({
            borrowerId: searchParams.get("borrowerId"),
            appId: searchParams.get("app_id"),
            bankId: values.bank.bankId,
            accountType: values.account_type,
            accountNumber: values.account_number,
            statementDuration: values.duration,
            bankName: values.bank.name,
            accountName: values.account_name
        })
        setLoading({...loading, initialize: true})
        IncomeService.openBankingInitializeConsent(payload)
            .then((response) => response.data)
            .then((res) => {
                setLoading({...loading, initialize: false})
                if(res.status==="success") {
                    setInitializeResponse(res.data)
                    setShowOTPModal(true)
                }
            })
            .catch((err) => {
                setLoading({...loading, initialize: false})
                handleRequestErrors(err)
            })
    }

    const handleOTPVerification = () => {
        let payload = JSON.stringify({
            consentId: initializeResponse?.consentId,
            verificationCode: OTP,
        })
        setLoading({...loading, verification: true})
        IncomeService.openBankingGetTransactions(payload)
            .then((response) => response.data)
            .then((res) => {
                setLoading({...loading, verification: false})
                if(res.status==="success") {
                    setOTPView(false)
                    // history(`/customer/edoc-success?${searchParams.toString()}`)
                } else {
                    toast.error(res?.message?.message)
                }
            })
            .catch((err) => {
                setLoading({...loading, verification: false})
                handleRequestErrors(err)
            })
    }


    useEffect(()=>{
        if(!!form.values.bank && form.values.account_number.length===10){
            const nip_code = vfd_banks?.find(item => item?.bank_code===form?.values?.bank?.bankCode)?.nip_code
            setNameField({
                ...nameField,
                disabled: !!nip_code,
                loaded: false
            })
            let payload = JSON.stringify({
                bankCode: nip_code,
                accountNumber: form.values.account_number,
            })
            RecovaService.directDebitNameEnquiry(payload)
                .then(response=> response.data)
                .then(res => {
                    if(res.status===true){
                        form.setFieldValue("account_name", res.data);
                        setNameField({
                            ...nameField,
                            loaded: true
                        })
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                });
        } else {
            setNameField({
                ...nameField,
                loaded: false
            })
            form.setFieldValue("account_name", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form.values.bank, form.values.account_number])

    return (
        <div className="max-w-[500px] p-[20px] block mx-auto bg-cc-primary bg-[url('./images/svg/sidebar/bg-image.svg')] rounded-[10px]">
            <img src={logo} alt="logo" className="w-[200px] mx-auto block mb-6 mt-[20px]" />
            <h1 className="text-white font-bold text-[30px] text-center mx-10 md:mx-0">Creditchek Africa request access to your Bank account information</h1>
            <FormikProvider value={form}>
                <form onSubmit={form.handleSubmit} className="box-border mt-10">
                    <TextField
                        name='account_number'
                        type='text'
                        placeholder="Enter your account number"
                    />
                    <SelectField
                        name='bank'
                        options={banks.map(item => ({
                            value: item,
                            label: <div className="flex items-center gap-x-2.5">
                                <img src={item.icon} alt="icon" className="-[20px] h-[20px]" />
                                <p>{item.name}</p>
                            </div>
                        }))}
                        placeholder="Select Bank"
                        onChange={(selected)=>{
                            form.setFieldValue( "bank", selected.value )
                        }}
                        value={form?.values?.bank}
                        optionHoverColor="#DADADA"
                    />
                    <div className="relative">
                        <TextField
                            name='account_name'
                            type='text'
                            placeholder="Account name"
                            readOnly={nameField.disabled}
                        />
                        {
                            nameField.loaded &&
                            <svg className="absolute right-2.5 top-3" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_21025_208112)">
                                    <rect width="24" height="24" rx="12" fill="#E8EFFF"/>
                                    <rect x="0.75" y="0.75" width="22.5" height="22.5" rx="11.25" stroke="#2BBD35" stroke-width="1.5"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M17.0964 7.39162L9.93638 14.3016L8.03638 12.2716C7.68638 11.9416 7.13638 11.9216 6.73638 12.2016C6.34638 12.4916 6.23638 13.0016 6.47638 13.4116L8.72638 17.0716C8.94638 17.4116 9.32638 17.6216 9.75638 17.6216C10.1664 17.6216 10.5564 17.4116 10.7764 17.0716C11.1364 16.6016 18.0064 8.41162 18.0064 8.41162C18.9064 7.49162 17.8164 6.68162 17.0964 7.38162V7.39162Z" fill="#2BBD35"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_21025_208112">
                                        <rect width="24" height="24" rx="12" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        }
                    </div>
                    <SelectField
                        name='account_type'
                        options={["Personal Account", "Business Account"].map(item => ({
                            value: item.split(" ")[0],
                            label: item
                        }))}
                        placeholder="Select Account Type"
                        onChange={(selected)=>{
                            form.setFieldValue( "account_type", selected.value )
                        }}
                        value={form?.values?.account_type}
                        optionHoverColor="#DADADA"
                    />
                    <SelectField
                        name='duration'
                        options={[
                            { label: "3 months", value: 3 },
                            { label: "6 months", value: 6 },
                            { label: "9 months", value: 9 },
                            { label: "12 months", value: 12 },
                        ].map(item => ({
                            value: item.value,
                            label: item.label
                        }))}
                        placeholder="Select statement duration"
                        onChange={(selected)=>{
                            form.setFieldValue( "duration", selected.value )
                        }}
                        value={form?.values?.duration}
                        optionHoverColor="#DADADA"
                    />
                    <div className="mx-10 md:mx-0">
                        <p className="text-white text-center">By clicking ‘Allow’, you also agree to our Use End User Terms of Use and Privacy Policy</p>
                        <div className="grid grid-cols-2 gap-x-2.5 mt-[60px]">
                            <Button
                                isValid={true}
                                // isLoading={loading}
                                type="button"
                                text="Cancel"
                                className="bg-transparent font-medium !text-[#98A2B3] !border-[#D0D5DD] border"
                            />
                            <Button
                                isValid={true}
                                // isLoading={loading}
                                type="submit"
                                text="Allow"
                                className="!bg-white !text-cc-primary font-medium border !border-white"
                            />
                        </div>
                    </div>
                    <div className="flex items-center justify-center gap-x-2 mt-6 mb-[60px]">
                        <p className="text-xs text-white font-bold">Powered by</p>
                        <img src={logo} alt="logo" className="w-[90px]" />
                    </div>
                </form>
            </FormikProvider>
            <Modal show={showOTPModal} onHide={()=>setShowOTPModal(false)} backdrop="static" keyboard={false} aria-labelledby="contained-modal-title-vcenter" centered >
                <div 
                    className={clsx(
                        "m-[20px]",
                        (initializeResponse?.verificationType===2 && OTPView) ? "":"flex flex-col justify-center items-center"
                    )}
                >
                    <MdClear className="absolute top-2.5 right-3 scale-[1.5] cursor-pointer text-[#667085]" onClick={()=>setShowOTPModal(false)} />
                    {
                        OTPView ?
                        (
                            initializeResponse?.verificationType===2 ?
                            <div>
                                <img src={pendingIcon} alt="" className="block mx-auto" />
                                <p className="text-[30px] text-[#0C0C0C] font-bold text-center my-6">Pending Approval</p>
                                <div>
                                    {
                                        [
                                            <span>Login to your <span className="font-bold">Bank App</span> to approve the request before you can proceed.</span>,
                                            <span>Approve the request then click on the “<span className="font-bold">Continue</span>” button below</span>,
                                            <span>The request will timeout in <span className="font-bold">10 minutes</span></span>
                                        ].map((item, idx)=>(
                                            <div className="flex">
                                                <div className="bg-cc-primary w-6 h-6 rounded-full text-center shrink-0 z-20">
                                                    <p className="text-[20px] font-bold text-white">{idx+1}</p>
                                                </div>
                                                <p 
                                                    className={clsx(
                                                        "text-[17px] pl-6 pb-2.5 -ml-3 z-10",
                                                        idx!==2 && "border-l-[2px] border-l-cc-primary"
                                                    )}
                                                >{item}</p>
                                            </div>
                                        ))
                                    }
                                </div>
                                <Button
                                    text="Continue"
                                    type="button"
                                    isValid={true}
                                    fullwidth={true}
                                    className="bg-cc-primary font-semibold mt-[20px]"
                                    onClick={()=>setOTPView(true)}
                                />
                            </div>
                            :
                            <div>
                                <p className="text-[20px] text-[#0C0C0C] font-bold opacity-50">OTP Verification</p>
                                <p className="text-sm text-[#1D2939] mb-[20px] opacity-50">Enter {initializeResponse?.additionalInfo?.hintMessage || "OTP sent to you"}</p>
                                <div>
                                    <OTPInput 
                                        value={OTP} 
                                        onChange={setOTP} 
                                        autoFocus 
                                        OTPLength={6} 
                                        otpType="number" 
                                        disabled={false} 
                                        secure={false} 
                                        className="flex gap-x-[16px]"
                                        inputClassName="border-[1px] border-[#99B8FF] rounded bg-[#E5EEFF] !mr-0"
                                    />
                                </div>
                                <p
                                    className={clsx(
                                        "mt-2.5 text-sm",
                                        loading.initialize ? "cursor-wait":"cursor-pointer"
                                    )}
                                    onClick={()=>{
                                        if(!loading.initialize){
                                            handleInitialize(form.values)
                                        }
                                    }}
                                >Resend OTP</p>
                                <div className="w-[300px] mt-7 grid grid-cols-2 gap-x-2.5">
                                        <Button
                                            text="Go Back"
                                            type="button"
                                            isValid={true}
                                            fullwidth={true}
                                            className="bg-white border-[1px] border-[#D0D5DD] py-2 !text-black"
                                            onClick={()=>setShowOTPModal(false)}
                                        />
                                        <Button
                                            text="Verify"
                                            type="button"
                                            isValid={true}
                                            fullwidth={true}
                                            className="bg-cc-primary font-semibold py-2"
                                            onClick={()=>handleOTPVerification()}
                                        />
                                </div>
                            </div>
                        )
                        :
                        <div>
                            <p className="text-[17px] text-[#0C0C0C] font-medium text-center my-6">Bank Statement Uploaded Succesfully</p>
                            <p className="text-[17px] text-[#0C0C0C] font-medium text-center mb-6">Do you want to upload another statement from your other listed banks?</p>
                            <div className="w-[100px] block mx-auto">
                                <Button
                                    text="Yes"
                                    type="button"
                                    isValid={true}
                                    fullwidth={true}
                                    className="bg-cc-primary font-semibold py-2"
                                    onClick={()=>setOTPView(true)}
                                />
                            </div>
                        </div>
                    }
                </div>
            </Modal>
        </div>
    )
}