import { TextField } from "components/Form/TextField";
import { useState } from "react";
import {Modal} from "react-bootstrap";
import { Formik, Form } from 'formik';
import * as Icon from "react-icons/fi";
import Checkbox from "react-custom-checkbox";
import { object, string } from "yup";
import { AuthService } from "services/auth.service";
import { logToBin } from "lib/logs";
import { toast } from "react-toastify";
import Switch from "react-switch";
import Button from "components/shared/Button";
import clsx from "clsx";
import { useEffect } from "react";
import { BsPlusCircleFill } from "react-icons/bs";
import { handleRequestErrors } from "utils/helpers";
import dropdown from "images/svg/table_dropdown.svg";
import OutsideClickHandler from 'react-outside-click-handler';
import { FiEdit } from "react-icons/fi";


export default function CreateSubsidiary ({show, edit, handleClose}) {
    const [enabled, setEnabled] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);
    const urlRegex = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm
    const [urlEditDisabled, setUrlEditDisabled] = useState(true);
    const [service, setService] = useState({})

    useEffect(()=>{
        setEnabled(edit?.status)
        setService({
            credit_premium: edit?.credit_premium || false,
            credit_advanced: edit?.credit_advanced || false,
            income_insight: edit?.income_insight || false,
            income_transaction: edit?.income_transaction || false,
            pdf_upload: edit?.pdf_upload || false,
            customer_onboarded: edit?.customer_onboarded || false,
            mandate_created_event: edit?.mandate_created_event || false,
            mandate_expire_event: edit?.mandate_expire_event || false,
            mandate_rejected_event: edit?.mandate_rejected_event || false,
            mandate_approved_event: edit?.mandate_approved_event || false,
            mandate_paused_event: edit?.mandate_paused_event || false,
            mandate_canceled_event: edit?.mandate_canceled_event || false,
            mandate_reinstated_event: edit?.mandate_reinstated_event || false,
            mandate_advance_notification_event: edit?.mandate_advance_notification_event || false,
            debit_successful_event: edit?.debit_successful_event || false,
            debit_failed_event: edit?.debit_failed_event || false,
            insufficient_balance_event: edit?.insufficient_balance_event || false,
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [edit])

    const handleWebhookSubmit = (url) => {
        setIsLoading(true)
        const payload = JSON.stringify({
            url,
            ...service
        })
        if(!!edit){
            AuthService.updateWebhook(payload)
                .then((response) => response.data)
                .then((res) => {
                    setIsLoading(false)
                    if (res.success === true) {
                        toast.success('Event Webhook edited!');
                        handleClose();
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    handleRequestErrors(err)
                });
        } else {
            AuthService.createWebhook(payload)
                .then((response) => response.data)
                .then((res) => {
                    setIsLoading(false)
                    if (res.success === true) {
                        toast.success('Event Webhook created!');
                        handleClose();
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    setIsLoading(false)
                    handleRequestErrors(err)
                });
        }
    }

    const toggleStatus = (url) => {
        const payload = JSON.stringify({
            url,
        })
        AuthService.toggleWebhookStatus(payload)
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true) {
                    toast.success('Event status edited!');
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    toast.error(err.response.data.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    const handleDelete = () => {
        setIsDeleting(true)
        AuthService.deleteWebhook()
            .then((response) => response.data)
            .then((res) => {
                setIsDeleting(false)
                if (res.success === true) {
                    toast.success('Event Webhook deleted!');
                    handleClose()
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                setIsDeleting(false)
                handleRequestErrors(err)
            });
    }

    return(
        <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter" centered >
            <div>
                <Formik
                    initialValues={{
                        url: edit?.url || '',
                    }}
                    validationSchema={object({
                        url: string().required("URL is required").matches(urlRegex,'URL is not valid')
                    })}
                    onSubmit={(values) => {
                        handleWebhookSubmit(values.url)
                    }}
                >  
                    {(props) => (
                        <Form>
                            <div className="px-[20px] pt-[20px]">
                                <h3 className="text-[20px] font-bold mb-7">{!edit ? "Add new":'Edit'} Event Webhook</h3>
                                <div 
                                    className={clsx(
                                        "box-border",
                                    )}
                                >
                                    {
                                        !!edit &&
                                        <>
                                            <div className="absolute top-[20px] right-[20px]">
                                                <Button
                                                    isValid={true}
                                                    text="Delete Webhook"
                                                    fullwidth={false}
                                                    type="button"
                                                    className="!w-[160px] !py-1.5 !bg-[#DE213C]"
                                                    isLoading={isDeleting}
                                                    onClick={handleDelete}
                                                />
                                            </div>
                                            <div className="flex items-center  -mt-6">
                                                <div className="scale-[0.8] pt-2 -ml-2">
                                                    <Switch 
                                                        onChange={()=>{toggleStatus(props?.values?.url);setEnabled(!enabled);}} 
                                                        checked={enabled} 
                                                        uncheckedIcon={false}
                                                        checkedIcon={false}
                                                        onColor="#2BBD35"
                                                        offColor="#DE213C"
                                                    />
                                                </div>
                                                <p 
                                                    className={clsx(
                                                        "text-base",
                                                        enabled?"text-cc-green-2":"text-[#DE213C]",
                                                    )}
                                                >
                                                    {
                                                        enabled ? "ON":"OFF"
                                                    }
                                                </p>
                                            </div>
                                        </>
                                    }
                                    <div className="relative">
                                        <TextField
                                            label="Webhook (POST) secured URL"
                                            required
                                            name='url'
                                            type='text'
                                            disabled={!!edit ? urlEditDisabled : false}
                                            placeholder="htpps://example.com"
                                        />
                                        {
                                            !!edit &&
                                            <div className="absolute bottom-8 right-2.5">
                                                <Button
                                                    isValid={true}
                                                    text={urlEditDisabled ? "Edit":"Save"}
                                                    fullwidth={false}
                                                    type="button"
                                                    className="!px-2.5 !py-1 !rounded !bg-transparent text-xs !text-cc-primary border border-[#DADADA]"
                                                    icon={<FiEdit className="w-[15px] text-cc-primary" />}
                                                    iconPosition="right"
                                                    onClick={()=>setUrlEditDisabled(!urlEditDisabled)}
                                                />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {
                                [
                                    {
                                        name: "Identity Insight",
                                        disabled: false,
                                        options: [
                                            {
                                                name: "New Customer Onboarded",
                                                value: "customer_onboarded"
                                            },
                                        ]
                                    },
                                    {
                                        name: "Credit Insight",
                                        disabled: false,
                                        options: [
                                            {
                                                name: "Premium Updates",
                                                value: "credit_premium"
                                            },
                                            {
                                                name: "Advanced Updates",
                                                value: "credit_advanced"
                                            },
                                        ]
                                    },
                                    {
                                        name: "Income Insights",
                                        disabled: false,
                                        options: [
                                            {
                                                name: "Insight successful",
                                                value: "income_insight"
                                            },
                                            {
                                                name: "Statement transactions",
                                                value: "income_transaction"
                                            },
                                            {
                                                name: "Failure (error)",
                                            },
                                            {
                                                name: "PDF statement uploaded",
                                                value: "pdf_upload"
                                            },
                                        ]
                                    },
                                    {
                                        name: "RecovaPRO",
                                        disabled: false,
                                        options: [
                                            {
                                                name: "Mandate created event",
                                                value: "mandate_created_event"
                                            },
                                            {
                                                name: "Mandate expired event",
                                                value: "mandate_expire_event"
                                            },
                                            {
                                                name: "Mandate Rejected event",
                                                value: "mandate_rejected_event"
                                            },
                                            {
                                                name: "Mandate paused event ",
                                                value: "mandate_paused_event",
                                            },
                                            {
                                                name: "Mandate Approved event",
                                                value: "mandate_approved_event"
                                            },
                                            {
                                                name: "Mandate Canceled event",
                                                value: "mandate_canceled_event"
                                            },
                                            {
                                                name: "Mandate Reinstated event",
                                                value: "mandate_reinstated_event"
                                            },
                                            {
                                                name: "Mandate advance notification event",
                                                value: "mandate_advance_notification_event"
                                            },
                                            {
                                                name: "Collections successful event",
                                                value: "debit_successful_event"
                                            },
                                            {
                                                name: "Collections failed event",
                                                value: "debit_failed_event"
                                            },
                                            {
                                                name: "Insufficient merchant wallet balance",
                                                value: "insufficient_balance_event"
                                            },
                                        ]
                                    },
                                    {
                                        name: "Spectrum (Coming Soon)",
                                        disabled: true,
                                    },
                                ].map((item, idx)=>(
                                    <div key={idx}>
                                        <WebhookItem item={item} selections={service} setService={setService} />
                                    </div>
                                ))
                            }
                            <div className="flex justify-center gap-x-2.5 px-[20px] py-[30px]">
                                <button 
                                    type="button" 
                                    className="w-[160px] bg-white border-[1px] border-cc-grey-22 py-[5px] rounded-[7px] text-cc-grey-22"
                                    onClick={handleClose}
                                >
                                    Cancel
                                </button>
                                <div>
                                    <Button
                                        isValid={true}
                                        text="Save"
                                        type="submit"
                                        className="!w-[160px] !bg-cc-primary"
                                        isLoading={isLoading}
                                        icon={<BsPlusCircleFill className="w-[20px] text-white" />}
                                        iconPosition="left"
                                    />
                                </div>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </Modal>
    )
}

const WebhookItem = ({item, selections, setService}) => {
    const [showOption, setShowOption] = useState(false)
    const mainSelection = item?.options?.every(prop => selections[prop?.value] === true) || false

    return (
        <div className="px-[20px] py-2 box-border flex items-center gap-x-2">
            <input 
                type="radio" 
                checked={mainSelection}
                onChange={()=>{
                    setService((prevState) => {
                        const updatedState = { ...prevState };
                        item.options.forEach((prop) => {
                            if (prop.value in updatedState) {
                                updatedState[prop.value] = !mainSelection;
                            }
                        });
                        return updatedState;
                    });
                }}
                disabled={item.disabled}
            />
            <label className="capitalize" >{item.name}</label>
            <div className="relative">
                <img 
                    src={dropdown} 
                    alt="dropdown" 
                    className={clsx(
                        "w-6 cursor-pointer transition-all transform",
                        showOption && "rotate-180"
                    )} 
                    onClick={()=>{
                        if(!item.disabled){
                            setShowOption(!showOption)
                        }
                    }} 
                />
                {
                    showOption &&
                    <div className="absolute p-2.5 z-[99] left-0 top-6 bg-white rounded shadow-md">
                        <OutsideClickHandler
                            onOutsideClick={() => {
                                setShowOption(false)
                            }}
                        >
                            <div className="flex flex-col gap-y-1.5">
                                {
                                    item.options.map((option) => (
                                        <div key={option.value}>
                                            <Checkbox
                                                icon={<Icon.FiCheck color="black" size={14} />}
                                                name="my-input"
                                                checked={selections[option.value]}
                                                onChange={(value) => {
                                                    setService({...selections, [option.value]: value})
                                                }}
                                                disabled={!option.value}
                                                borderColor="black"
                                                style={{ cursor: "pointer" }}
                                                labelStyle={{ marginLeft: 5, userSelect: "none", whiteSpace: "nowrap" }}
                                                label={option.name}
                                            />
                                        </div>
                                    ))
                                }
                            </div>
                        </OutsideClickHandler>
                    </div>
                }
            </div>
        </div>
    )
}