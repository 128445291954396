import Table from "components/Table";
import view from "images/svg/recova/view.svg"
import copy from "images/svg/recova/copy.svg"
import smiley from "images/svg/recova/empty.svg";
// import consentDownload from "images/svg/recova/consent-download.svg"
import clsx from "clsx";
import { copyText, currencyFormatter, dateFormatter, handleRequestErrors } from "utils/helpers";
import { useEffect, useState } from "react";
import { RecovaService } from "services/recova.service";
import ShareMandateModal from "./ShareMandateModal";
import { useUser } from "hooks/redux.hook";
import { useSelector } from "react-redux";
import dropdown from "images/svg/table_dropdown.svg";
import OutsideClickHandler from "react-outside-click-handler";
import { toast } from "react-toastify";
import ModalLayout from "../../layout/ModalLayout";
import { FaCheckCircle } from "react-icons/fa";
import Button from "components/shared/Button";
import ToggleButton from "components/shared/ToggleButton";
import Filter from "components/Table/Filter";
import play from "images/svg/recova/play.svg"
import InitiateDebit from "./InitiateDebit";
import { Tooltip } from "react-tooltip";


export default function HistoryTable ({title, icon, reloadHistory}) {
    const [history, setHistory] = useState([])
    const [historyLoading, setHistoryLoading] = useState(true)
    const [showModal, setShowModal] = useState(false);
    const [mandateData, setMandateData] = useState(null)
    const {b2b_data} = useUser()
    const appId = useSelector(state => state?.app?.active?._id)
    const [activeAction, setActiveAction] = useState(null)
    const [viewData, setViewData] = useState(null)
    const [showStatusSuccess, setShowStatusSuccess] = useState({
        show: false,
        type: "",
        loading: false,
        id: "",
    })
    const [repaymentDetails, setRepaymentDetails] = useState([]);
    const [searchTerm, setSearchTerm] = useState("")
    const [filterTerm, setFilterTerm] = useState("all");
    const [showInitiatDebitModal, setShowInitiatDebitModal] = useState(false)

    useEffect(()=>{
        getMandates()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadHistory, searchTerm, filterTerm])

    useEffect(()=>{
        if(!!viewData){
            RecovaService.getMandateById(viewData?._id)
                .then(response=> response.data)
                .then(res => {
                    if(res.status===true) {
                        setRepaymentDetails(res?.data?.repayment)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                });
        }
    }, [viewData])

    const getMandates = () => {
        setHistoryLoading(true)
        RecovaService.getAllMandates(searchTerm, filterTerm)
            .then(response=> response.data)
            .then(res => {
                setHistoryLoading(false)
                if(res.status===true) {
                    setHistory(res?.data?.reverse())
                }
            })
            .catch((err) => {
                setHistoryLoading(false)
                handleRequestErrors(err, true)
            });
    }

    const updateMandateStatus = (type, id) => {
        setShowStatusSuccess({
            ...showStatusSuccess,
            type: type,  
            loading: true
        })
        const payload = JSON.stringify({
            mandateId: id
        })
        RecovaService.updateMandateStatus(payload, type)
            .then(response=> response.data)
            .then(res => {
                setShowStatusSuccess({...showStatusSuccess, loading: false})
                if(res.status) {
                    setShowStatusSuccess({show: true, type: type, loading: false, id: id})
                    toast.success(`Mandate ${type}${type==="cancel"?"led":"d"} successfully.`)
                    getMandates()
                }
            })
            .catch((err) => {
                setShowStatusSuccess({...showStatusSuccess, loading: false})
                handleRequestErrors(err)
            });
    }

    const toggleBalanceEnquiry = (id) => {
        const payload = JSON.stringify({
            mandateId: id
        })
        RecovaService.toggleBalanceEnquiry(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.status) {
                    setViewData({
                        ...viewData,
                        balanceEnquiry: !viewData.balanceEnquiry
                    })
                    toast.success(`Balance enquiry has been updated for this mandate.`)
                    getMandates()
                }
            })
            .catch((err) => {
                handleRequestErrors(err)
            });
    }

    function getMonthsBetweenDates(date1_, date2_) {
        let date1 = new Date(date1_)
        let date2 = new Date(date2_)
        if (date1 > date2) {
          let temp = date1;
          date1 = date2;
          date2 = temp;
        }
        
        let yearsDifference = date2?.getFullYear() - date1?.getFullYear();
        let monthsDifference = date2?.getMonth() - date1?.getMonth();
      
        return yearsDifference * 12 + monthsDifference;
      }

    const  handleChange = (e) => {
        setSearchTerm(e.target.value)
    }

    return (
        <div>
            <Table
                tableTitle={title}
                searchPlaceHolder={"Search by BVN and MandateRef"}
                onSearchChange={handleChange}
                searchContainerClass="min-w-[400px]"
                searchInputClass="!text-sm !text-cc-grey-4"
                searchIconFirst={true}
                slot={
                    <Filter
                        filterValue={filterTerm}
                        onChange={(v)=>setFilterTerm(v)}
                        options={[
                            { label: "Filter", value: "all", showOnList: false },
                            { label: "All", value: "all", showOnList: true },
                            { label: "Pending", value: "pending", showOnList: true },
                            { label: "Active", value: "active", showOnList: true },
                            { label: "Paused", value: "paused", showOnList: true },
                            { label: "Deactivated", value: "deactivated", showOnList: true },
                        ]}
                        hideCustom={true}
                        extraClass={"!text-sm"}
                        optionClass={"!text-sm"}
                        containerClass="!h-[40px] !rounded-[8px] pt-1"
                    />
                }
                titleIcon={icon}
                data={history}
                loading={historyLoading}
                hideSearch={false}
                tableClassName={clsx(
                    "min-w-[1100px]",
                    !!viewData && "mb-[200px]",
                    !!activeAction && "mb-[80px]",
                )}
                trClassName="relative"
                tableEmpty={
                    <div className="flex items-center my-[120px] mx-[20px] justify-center">
                        <p className="text-cc-grey-4 mr-3">No mandates exist yet!</p>
                        <img src={smiley} alt="" className="w-[40px]" />
                    </div>
                }
                rows={[
                    {
                        header:'Product Name',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] capitalize text-sm font-medium pr-[5px]">
                                {item?.productId?.name||"Not available"}
                            </span>
                        ),
                    },
                    {
                        header:'BVN',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] text-sm font-medium pr-[5px]">
                                {item?.bvn}
                            </span>
                        ),
                    },
                    {
                        header:'Amount Due',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] text-sm text-cc-grey-4 pr-[5px]">
                                {currencyFormatter(item?.totalAmountDue||0)}
                            </span>
                        ),
                    },
                    {
                        header:'Creation Date',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px] text-sm text-cc-grey-4">{dateFormatter(item?.createdAt, 'dd/mm/yyyy')}</span>
                        ),
                    },
                    {
                        header:'Start Date',
                        view: (item) => (
                            <span className="lowercase py-[10px] pl-[10px] text-sm pr-[5px] text-cc-grey-4">{dateFormatter(item?.startDate, 'dd/mm/yyyy')}</span>
                        ),
                    },
                    {
                        header:'End Date',
                        view: (item) => (
                            <span className="lowercase py-[10px] pl-[10px] text-sm pr-[5px] text-cc-grey-4">{dateFormatter(item?.endDate, 'dd/mm/yyyy')}</span>
                        ),
                    },
                    {
                        header:'Collection Mode',
                        view: (item) => (
                            <span className="py-[10px] pl-[10px] pr-[5px] text-sm text-cc-grey-4 capitalize">{item?.collectionMode}</span>
                        ),
                    },
                    {
                        header: <div className="flex items-center gap-1">
                            <p>Status</p>
                            <div className="cursor-pointer" onClick={()=>getMandates()}>                
                                <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.05802 9.4375C1.01973 9.13037 1 8.81753 1 8.5C1 4.35786 4.35786 1 8.5 1C10.8443 1 12.9374 2.07557 14.3127 3.76015M14.3127 3.76015V1M14.3127 3.76015V3.81243L11.5002 3.8125M15.942 7.5625C15.9803 7.86963 16 8.18247 16 8.5C16 12.6422 12.6422 16 8.5 16C6.25995 16 4.24927 15.018 2.875 13.4609M2.875 13.4609V13.1875H5.6875M2.875 13.4609V16" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                        </div>,
                        view: (item) => (
                            <div className="flex items-center text-sm pl-2.5">
                                <div 
                                    className={clsx(
                                        "w-2.5 h-2.5 rounded-full",
                                        item?.status==="pending" ? "bg-cc-yellow-2" : item?.status==="completed"||item?.status==="active" ? "bg-cc-green-2" : "bg-cc-red-8"
                                    )}
                                />
                                <p 
                                    className={clsx(
                                        "ml-1.5 font-bold",
                                        item?.status==="pending" ? "text-cc-yellow-2" : item?.status==="completed"||item?.status==="active" ? "text-cc-green-2" : "text-cc-red-8"
                                    )}
                                >{item?.status}</p>
                            </div>
                        ),
                    },
                    {
                        header:'',
                        view: (item) => (
                            <div className="flex my-2.5 mr-3">
                                <img 
                                    src={view} 
                                    alt="view" 
                                    className={clsx(
                                        "cursor-pointer mr-2.5",
                                        (item.status==="deactivated"||item.status==="closed")&&"grayscale opacity-50"
                                    )}
                                    onClick={()=>{
                                        if(item.status==="deactivated"||item.status==="closed"){
                                            toast.info(`This mandate has been ${item.status}`)
                                        } else {
                                            setMandateData(item);
                                            setShowModal(true)
                                        }
                                    }} 
                                />
                                <img 
                                    src={copy} 
                                    alt="copy" 
                                    className={clsx(
                                        "cursor-pointer mr-2.5",
                                        (item.status==="deactivated"||item.status==="closed")&&"grayscale opacity-50"
                                    )}
                                    onClick={()=>{
                                        if(item.status==="deactivated"||item.status==="closed"){
                                            toast.info(`This mandate has been ${item.status}`)
                                        } else {
                                            copyText(
                                                `${window.location.origin}/customer/direct-debit/${item?._id}/${b2b_data.user.businessId}?appId=${appId}&account=${item?.status==="active"||item?.consents?.length>0?"backup":"primary"}`, 
                                                "Mandate link copied!"
                                            )
                                        }
                                    }} 
                                />
                                <img 
                                    src={play} 
                                    alt="play" 
                                    className={clsx(
                                        "cursor-pointer scale-[1.2] w-3.5",
                                        (item.collectionMode==="auto"||item.status==="deactivated"||item.status==="closed")&&"hidden"
                                    )}
                                    data-tooltip-id={"initiate-debit"}
                                    onClick={()=>{
                                        if(item.collectionMode!=="auto"||item.status!=="deactivated"||item.status!=="closed"){
                                            setShowInitiatDebitModal(true)
                                            setMandateData(item);
                                        }
                                    }} 
                                />
                                <Tooltip 
                                    id={"initiate-debit"} 
                                    place="right"
                                    render={()=>(
                                        <div className="text-xs text-white w-[80px]">
                                            <p>Initiate Debit</p>
                                        </div>
                                    )} 
                                />
                                <div 
                                    className={clsx(
                                        "cursor-pointer w-[18px] mx-2 relative",
                                        (item.status==="deactivated"||item.status==="closed")&&"grayscale opacity-50" 
                                    )}
                                >
                                    <div 
                                        className="py-0.5" 
                                        onClick={()=>{
                                            if(item.status==="deactivated"||item.status==="closed"){
                                                toast.info(`This mandate has been ${item.status}`)
                                            } else {
                                                setActiveAction(item._id)
                                            }
                                        }}>
                                        <svg className="my-2" width="15" height="4" viewBox="0 0 15 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M1.875 0C0.838623 0 0 0.894532 0 2C0 3.10156 0.838623 4 1.875 4C2.91138 4 3.75 3.10156 3.75 2C3.75 0.894532 2.91138 0 1.875 0ZM7.5 0C6.46362 0 5.625 0.894532 5.625 2C5.625 3.10156 6.46362 4 7.5 4C8.53638 4 9.375 3.10156 9.375 2C9.375 0.894532 8.53638 0 7.5 0ZM13.125 0C12.0886 0 11.25 0.894532 11.25 2C11.25 3.10156 12.0886 4 13.125 4C14.1614 4 15 3.10156 15 2C15 0.894532 14.1614 0 13.125 0Z" fill="#0046E6"/>
                                        </svg>
                                    </div>
                                    {
                                        item._id===activeAction &&
                                        <OutsideClickHandler
                                            onOutsideClick={() => {
                                                setActiveAction(null)
                                            }}
                                        >
                                            <div className="absolute z-10 p-2 rounded bg-white shadow-md right-0 font-bold border-[1px] border-red-500">
                                                <div>
                                                    <p 
                                                        className={clsx(
                                                            "text-[#DC6803] whitespace-nowrap" ,
                                                            item.status!=="active"&&"hidden"
                                                        )}
                                                        onClick={()=>updateMandateStatus("pause", item._id)} 
                                                    >Pause Mandate</p>
                                                </div>
                                                <div>
                                                    <p 
                                                        className={clsx(
                                                            "text-[#027A48] whitespace-nowrap",
                                                            item.status!=="paused"&&"hidden" 
                                                        )}
                                                        onClick={()=>updateMandateStatus("resume", item._id)} 
                                                    >Resume Mandate</p>
                                                </div>
                                                <div>
                                                    <p 
                                                        className={clsx(
                                                            "text-[#D92D20] whitespace-nowrap",
                                                            item.status==="deactivated"&&"hidden" 
                                                        )}
                                                        onClick={()=>updateMandateStatus("cancel", item._id)} 
                                                    >Cancel Mandate</p>
                                                </div>
                                            </div>
                                        </OutsideClickHandler>
                                    }
                                </div>
                                <div>
                                    <img 
                                        src={dropdown} 
                                        alt="view" 
                                        className={clsx(
                                            "cursor-pointer w-[24px] shrink-0 transition-all absolute top-3 right-3",
                                            viewData===item && "rotate-180"
                                        )}
                                        onClick={()=>{
                                            if(!!viewData){
                                                setViewData(null)
                                            } else {
                                                setViewData(item)
                                            }
                                            setRepaymentDetails([])
                                        }} 
                                    />
                                    {
                                        viewData===item &&
                                        <div 
                                            className={clsx(
                                                "absolute top-[45px] bg-white z-10 w-[calc(100%-40px)] left-[20px] shadow-md border-[1px] border-cc-primary rounded p-[20px]",
                                            )}
                                        >
                                            <OutsideClickHandler
                                                onOutsideClick={() => {
                                                    setViewData(null)
                                                }}
                                            >
                                                <div className="grid grid-cols-5 gap-[20px]">
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Product Name</p>
                                                        <p>{item?.productId?.name||"Not available"}</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">BVN</p>
                                                        <p>{item.bvn}</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Amount Due</p>
                                                        <p>{currencyFormatter(item?.totalAmountDue||0)}</p>
                                                    </div>
                                                    <div className="text-sm">
                                                        <p className="font-bold">Mandate Type</p>
                                                        <p className="capitalize">Variable</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Advanced Notice Sent</p>
                                                        <p>True</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Collection Mode</p>
                                                        <p>{item?.collectionMode==="auto" ? "Automated":"Manual"}</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Mandate Status</p>
                                                        <p 
                                                            className={clsx(
                                                                "font-bold",
                                                                item?.status==="pending" ? "text-cc-yellow-2" : item?.status==="completed"||item?.status==="active" ? "text-cc-green-2" : "text-cc-red-8"
                                                            )}
                                                        >{item?.status}</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Total Oustanding</p>
                                                        <p>{currencyFormatter(item?.outstandingBalance||0)}</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Total Collected</p>
                                                        <p>{currencyFormatter(item?.collectedAmount||0)}</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Duration</p>
                                                        <p className="lowercase">{getMonthsBetweenDates(item?.startDate, item?.endDate)} month(s)</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Mandate Ref</p>
                                                        <div className="flex items-center gap-x-1">
                                                            <p>{item?.reference}</p>
                                                            <img src={copy} alt="copy" className="cursor-pointer w-[20px]" onClick={()=>copyText(item?.reference, "Mandate reference copied!")} />
                                                        </div>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Start Date</p>
                                                        <p>{dateFormatter(item?.startDate, 'dd/mm/yyyy')}</p>
                                                    </div>
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">End Date</p>
                                                        <p>{dateFormatter(item?.endDate, 'dd/mm/yyyy')}</p>
                                                    </div>
                                                    {
                                                        !!item.businessName &&
                                                        <div className="text-sm overflow-hidden">
                                                            <p className="font-bold">Business Name</p>
                                                            <p>{item.businessName||"Not available"}</p>
                                                        </div>
                                                    }
                                                    {
                                                        !!item.rcNumber &&
                                                        <div className="text-sm overflow-hidden">
                                                            <p className="font-bold">RC Number</p>
                                                            <p>{item.rcNumber||"Not available"}</p>
                                                        </div>
                                                    }
                                                    {
                                                        !!item.businessEmail &&
                                                        <div className="text-sm overflow-hidden">
                                                            <p className="font-bold">Business Email</p>
                                                            <p>{item.businessEmail||"Not available"}</p>
                                                        </div>
                                                    }
                                                    {
                                                        !!item.businessPhone &&
                                                        <div className="text-sm overflow-hidden">
                                                            <p className="font-bold">Business Phone Number</p>
                                                            <p>{item.businessPhone||"Not available"}</p>
                                                        </div>
                                                    }
                                                    <div className="text-sm overflow-hidden">
                                                        <p className="font-bold">Balance Enquiry</p>
                                                        <ToggleButton 
                                                            checked={item?.balanceEnquiry || false} 
                                                            onChange={()=>toggleBalanceEnquiry(item._id)} 
                                                            text={item?.balanceEnquiry ? "Active":"Inactive"}
                                                            textClass={clsx(
                                                                "text-base font-bold ",
                                                                item?.balanceEnquiry && "text-[#12B76A]"
                                                            )}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="flex gap-x-10">
                                                    {
                                                        (repaymentDetails?.length>0) &&
                                                            <div>
                                                                <p className="font-bold text-[20px] mt-7 mb-2">Mandate Breakdown</p>
                                                                <div>
                                                                    <div className="flex flex-col gap-y-1 text-cc-grey-4 text-sm">
                                                                        <div className="flex gap-x-10">
                                                                            <p>Due Date</p>
                                                                            <p>Amount</p>
                                                                        </div>
                                                                        {
                                                                            repaymentDetails?.map(item=>(
                                                                                <div className="flex gap-x-10">
                                                                                    <p>{dateFormatter(item.dueDate, 'dd-mm-yyyy')}</p>
                                                                                    <p>{currencyFormatter(item?.totalAmount || 0)}</p>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        (item?.consents?.length>0) &&
                                                            <div className="min-w-[200px]">
                                                                <p className="font-bold text-[20px] mt-7 mb-2">BVN Linked Banks</p>
                                                                <div>
                                                                    <p className="text-sm font-bold text-cc-grey-4 mb-1.5">Primary Account</p>
                                                                    <div className="flex items-center gap-x-2.5">
                                                                        <img src={item?.consents?.[0]?.bankLogo} alt="primary_acc" className="rounded w-[20px] h-[20px]" />
                                                                        <p className="text-sm folt-bold text-cc-grey-4">{item?.consents?.[0]?.bankName}</p>
                                                                        <div className="flex items-center gap-x-1.5">
                                                                            <div 
                                                                                className={clsx(
                                                                                    "rounded-xl h-[22px] py-[2px] px-2.5 text-xs ml-auto",
                                                                                    item?.consents?.[0]?.active ? "bg-[#F6FEF9] text-[#54BD95]":"bg-[#FFF4ED] text-[#FF0000]"
                                                                                )}
                                                                            >
                                                                                <p>{item?.consents?.[0]?.active ? "Active":"Inactive"}</p>
                                                                            </div>
                                                                            {
                                                                                // !item?.consents?.[0]?.active &&
                                                                                false &&
                                                                                <svg width="12" height="12" className="cursor-pointer" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M5.61817 1.47412C6.20383 0.886191 7.10588 0.838528 7.63298 1.36766C8.16008 1.8968 8.11259 2.80235 7.52693 3.39028L6.6786 4.24191M3.81546 5.20001C3.28837 4.67088 3.33585 3.76531 3.92151 3.17738L4.67422 2.42174" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                                    <path d="M5.18377 3.79688C5.71087 4.32601 5.66337 5.23156 5.07772 5.81949L4.22938 6.67111L3.38105 7.52277C2.79539 8.1107 1.89333 8.15834 1.36624 7.62921C0.839151 7.10007 0.886632 6.19452 1.47229 5.60659L2.32063 4.75493" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                                </svg>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        (item?.consents?.length>1) &&
                                                                        <div>
                                                                            <p className="text-sm font-bold text-cc-grey-4 mb-1.5 mt-2.5">Backup Account(s)</p>
                                                                            {
                                                                                item?.consents?.map((bank, idx)=>(
                                                                                    <div 
                                                                                        className={clsx(
                                                                                            "flex items-center gap-x-2.5 mb-1.5",
                                                                                            idx===0 && "hidden"
                                                                                        )}
                                                                                        key={idx}
                                                                                    >
                                                                                        <img src={bank?.bankLogo} alt="backup_acc" className="rounded w-[20px] h-[20px]" />
                                                                                        <p className="text-sm folt-bold text-cc-grey-4">{bank?.bankName}</p>
                                                                                        <div className="flex items-center gap-x-1.5">
                                                                                            <div 
                                                                                                className={clsx(
                                                                                                    "rounded-xl h-[22px] py-[2px] px-2.5 text-xs ml-auto",
                                                                                                    bank.active ? "bg-[#F6FEF9] text-[#54BD95]":"bg-[#FFF4ED] text-[#FF0000]"
                                                                                                )}
                                                                                            >
                                                                                                <p>{bank?.active ? "Active":"Inactive"}</p>
                                                                                            </div>
                                                                                            {
                                                                                                // !bank?.active &&
                                                                                                false &&
                                                                                                <svg width="12" height="12" className="cursor-pointer" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                                    <path d="M5.61817 1.47412C6.20383 0.886191 7.10588 0.838528 7.63298 1.36766C8.16008 1.8968 8.11259 2.80235 7.52693 3.39028L6.6786 4.24191M3.81546 5.20001C3.28837 4.67088 3.33585 3.76531 3.92151 3.17738L4.67422 2.42174" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                                                    <path d="M5.18377 3.79688C5.71087 4.32601 5.66337 5.23156 5.07772 5.81949L4.22938 6.67111L3.38105 7.52277C2.79539 8.1107 1.89333 8.15834 1.36624 7.62921C0.839151 7.10007 0.886632 6.19452 1.47229 5.60659L2.32063 4.75493" stroke="#0046E6" stroke-width="0.525001" stroke-linecap="round"/>
                                                                                                </svg>
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                ))
                                                                            }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                    }
                                                    {/* <div className="cursor-pointer flex gap-1 items-end">
                                                        <p className="font-extrabold text-sm text-cc-grey-3">View/Download e-Consent</p>
                                                        <img src={consentDownload} alt="" className="w-7" />
                                                    </div> */}
                                                </div>
                                            </OutsideClickHandler>
                                        </div>
                                    }
                                </div>
                            </div>
                        ),
                    },
                ]}
            />
            <ShareMandateModal
                show={showModal}
                link={`${window.location.origin}/customer/direct-debit/${mandateData?._id}/${b2b_data.user.businessId}?appId=${appId}&account=${mandateData?.status==="active"||mandateData?.consents?.length>0?"backup":"primary"}`}
                handleClose={()=>setShowModal(false)}
            />
            <ModalLayout
                show={showStatusSuccess.show}
                handleClose={()=>{
                    setShowStatusSuccess({...showStatusSuccess, show: false})
                }}
            >
                <div className="flex flex-col items-center py-10">
                    <FaCheckCircle className="w-10 scale-[2.5] text-[#12B76A]" />
                    <p className="text-[32px] font-medium text-center mt-[20px] mb-1.5">Mandate {showStatusSuccess.type}{showStatusSuccess.type==="cancel"?"led":"d"} successfully</p>
                    <p className="text-[20px] text-center">You have {showStatusSuccess.type}{showStatusSuccess.type==="cancel"?"led":"d"} the mandate for this customer.</p>
                    <div className="w-full grid grid-cols-2 gap-3 mt-4">
                        <Button
                            isValid={true}
                            className="bg-white border-[1px] border-[#D0D5DD] !text-[#344054]"
                            text="Back"
                            onClick={()=>{
                                setShowStatusSuccess({...showStatusSuccess, show: false})
                            }}
                        />
                        <Button
                            isValid={true}
                            className="bg-cc-primary"
                            text={
                                showStatusSuccess.type==="pause" ? "Resume"
                                : showStatusSuccess.type==="resume" ? "Pause"
                                : "Continue"
                            }
                            onClick={()=>{
                                if(showStatusSuccess.type==="pause"){
                                    updateMandateStatus("resume", showStatusSuccess.id)
                                }  else if (showStatusSuccess.type==="resume") {
                                    updateMandateStatus("pause", showStatusSuccess.id)
                                } else {
                                    setShowStatusSuccess({...showStatusSuccess, show: false})
                                }
                            }}
                            loading={showStatusSuccess.loading}
                        />
                    </div>
                </div>
            </ModalLayout>
            <InitiateDebit
                show={showInitiatDebitModal}
                handleClose={()=>setShowInitiatDebitModal(false)}
                mandateId={mandateData?._id}
            />
        </div>
    )
}