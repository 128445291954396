import {ModalFooter} from "react-bootstrap";
import {Routes, Route, Link} from "react-router-dom";
import Main from "./Main";
import Wallet from "./Wallet";
import Transactions from "./Transactions";
import Invoices from "./Invoices";
import InvoiceDetails from "./InvoiceDetails";
import {useEffect, useState, useRef} from "react";
import {Modal} from "react-bootstrap";
import { AuthService } from "services/auth.service";
import { IdentityService } from "services/identity.service";
import stamp from "../../../images/svg/home/stamp.svg"
import { toast } from "react-toastify";
import { logToBin } from "../../../lib/logs";
import { useSelector, useDispatch } from "react-redux";
import { setDpprCompliance } from "redux/login";
import { BankService } from "services/bank.service";

import balloons from "images/svg/balloons.svg";
import { setBanks } from "redux/banks";
import Button from "components/shared/Button";
import { MdCancel } from "react-icons/md";


function Home ({setDetailName}) {
    const [show, setShow] = useState(true);
    const listInnerRef = useRef();
    const [btnValid, setBtnValid] = useState(true);
    const b2b_data = useSelector(state => state?.loginData?.data)
    const [announceRadar, setAnnounceRadar] = useState(false);
    const dispatch = useDispatch();
    const [policyLoading, setPolicyLoading] = useState(false)
    
 
    const handleClose = () => setShow(false);
    
    let pendingStatus = '';
    try{
        let stat = sessionStorage.getItem('b2bStatus');
        pendingStatus = JSON.parse(stat).isActive;
    } catch {}

    useEffect(()=>{
        setDetailName("")
    },[setDetailName]);
    
    useEffect(()=>{
        window.scrollTo(0, 0);

        BankService.getAllBankLogo()
        .then(resp=>{
            dispatch(setBanks(resp.data))
        })

        if(b2b_data?.isDpprCompliant){
            setShow(false)
        } else {
            setShow(true)
        }

        if(sessionStorage.getItem('cc_spectrum1.0')==="seen"){
            setAnnounceRadar(false)
        } else {
            setAnnounceRadar(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
  
    const onScroll = () => {
      if (listInnerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
        if (scrollTop + clientHeight === scrollHeight) {
          setBtnValid(true)
        }
      }
    };

    const acceptPolicy = () => {
        if(btnValid){
            setPolicyLoading(true)
            let payload = JSON.stringify({
                agree: true,
                loginEmail: b2b_data?.user?.email
            })
            IdentityService.businessPolicy(payload)
                .then((response) => response.data)
                .then((res) => {
                    setPolicyLoading(false)
                    if (res.status === true) {
                        handleClose();
                        dispatch(setDpprCompliance(true))
                    } else {
                        // do sth
                    }
                })
                .catch((err) => {
                    setPolicyLoading(false)
                    logToBin(err.response.data, window.location.href);
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                });
        }
    }

    const doReminder = () => {
        let payload = JSON.stringify({
            businessName: b2b_data?.businessName
        });
        AuthService.approvalReminder(payload)
            .then((response) => response.data)
            .then((res) => {
                if(res.success===true){
                    toast.success(res.message)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                logToBin(err.response.data, window.location.href);
                if(err.message === "timeout of 15000ms exceeded"){
                    toast.error('Request timed out, please try again!')
                } else if (err.message==="Network Error"){
                    toast.error('Network error, please make sure you are connected to the internet!')
                } else{
                    toast.error("Your request can't be processed at this time, please try again later!");
                }
            });
    }

    const closeAnnouncement = () => {
        sessionStorage.setItem( "cc_spectrum1.0", "seen" );
        setAnnounceRadar(false);
    }

    return(
        <div className="home">
            <Modal show={pendingStatus==="pending"?false:announceRadar} onHide={()=>closeAnnouncement()} backdrop="static" keyboard={false} size="md" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="p-10 sm:px-[20px] relative">
                    <MdCancel className="absolute w-10 scale-[1.7] cursor-pointer top-3 right-0" onClick={()=>closeAnnouncement()} />
                    <img src={balloons} alt="radar" className="w-[200px] block mx-auto"/>
                    <p className="text-[18px] text-[#7E7E7E] mt-2.5">Introducing</p>
                    <div>
                        <h3 className="font-extrabold text-[24px]">RecovaPRO Direct Debit</h3>
                        <p className="mt-1 text-sm font-medium italic">The ultimate solution to secure recurring payments and loan recovery. Simplify your debt recovery process and safeguard your loan transactions with our innovative direct debit product.</p>
                    </div>
                    <h4 className="font-bold text-[18px] mt-4">Features</h4>
                    <ul className="list-disc text-sm italic ml-3.5">
                        <li>Setup one-time loan repayments</li>
                        <li>Setup recurring loan repayments</li>
                        <li>Setup mandate on multiple accounts</li>
                        <li>Secure principal with Loan Insurance</li>
                    </ul>
                    <div className="flex justify-center mt-[20px]" onClick={()=>closeAnnouncement()}>
                        <Link to="/recova">
                            <button className="rounded-[25px] text-white bg-cc-primary border-[3px] border-cc-primary w-full box-border py-2 px-4 font-bold text-sm">Check out RecovaPRO</button>
                        </Link>
                    </div>
                </div>
            </Modal>
            <Modal show={pendingStatus==="pending"?false:show} onHide={handleClose} backdrop="static" keyboard={false} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div className="p-[20px]">
                    <h4 className="text-[1.4em] font-semibold mb-6 text-cc-primary">Data Protection and Privacy Policy</h4>
                    <div onScroll={onScroll} ref={listInnerRef}>
                        <p className="mb-2.5">This Data Protection Policy in conjunction with our Privacy Policy <span className="cursor-pointer text-cc-primary">&#40;https://creditchek.africa/privacy-policy&#41;</span> expressed Creditchek which hereinafter may be referred to as (“our” “we” or “the Company”) assurance and commitment to treating the information of customers, stakeholders, partners, and other interested parties (“You or Your”) with the utmost care and confidentiality by the Nigeria Data Protection Regulations and each jurisdiction which data subject who we process data.</p>
                        <p className="mb-2.5">With the provisions of this data protection policy and our privacy policy, we acknowledge that we gather, store, and handle data fairly, transparently, and with utmost duty of care and respect towards individual rights as identified under the NDPR.</p>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">1.0 Scope</h5>
                        <p className="mb-2.5">This document is prepared in accordance with the provisions of the EU General Data Protection Regulation (GDPR) and the Nigerian Data Protection Regulation (NDPR). It sets out how CreditChek Technologies Nigeria Limited (CreditChek) applies and complies with the principles of the regulations in processing the personal data of individuals, clients, vendors, and even third parties that interact with CreditChek.</p>
                        <p className="mb-2.5">For personal data of individuals, this document also highlights their rights and covers the data subject(s) whose personal data is collected and processed in compliance with the GDPR/NDPR.</p>
                        <p className="mb-2.5">As part of CreditChek’s operation, we often obtain and process information which includes names, addresses, phone numbers, Emails, bank verification number, national identity number, loan/credit history, bank account credit and debit data. We collect this information securely and transparently and only with your full consent/cooperation and knowledge with an aim to enable assess and determine your credit worthiness.</p>
                        <p className="mb-2.5">To ensure the protection of this information, we apply the following rules.</p>
                        <ul className="list-disc ml-[20px]">
                            <li>Use for lawful purpose only</li>
                            <li>Protect against any unauthorized and illegal access</li>
                            <li>Only share with the third party to provide services</li>
                        </ul>
                        <p className="mb-2.5">To exercise the provisions of data protection regulations and show our commitment to ensure our compliant and adhere to the provisions, We:</p>
                        <ul className="list-disc ml-[20px]">
                            <li>Restrict and monitor access to sensitive data</li>
                            <li>Develop transparent data collection procedures</li>
                            <li>Train employees in online privacy and security measures</li>
                            <li>Build secure networks to protect online data from cyberattacks</li>
                            <li>Establish clear procedures for reporting privacy breaches or data misuse</li>
                            <li>Establish data protection practices (document shredding, secure locks, data encryption, frequent backups, access authorization, etc.)</li>
                        </ul>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">2.0 How We Obtain Your Personal Data</h5>
                        <p className="mb-2.5">We obtain your personal data when you use our service through the registration process. In the process of signing up, we request data such as name, phone number, address, Email, Company name, Designation, and Country. Through a third-party source, in the process of funding your wallet, you will be directed to our payment institution platform where you will be required to input your card details for such funding. Please note that the data at this stage is not stored on the Creditchek platform but the third party. Likewise, while using our services, you may be required to fill in your details and/or your customer’s details during the use of our Phone book, Teams, Group, or Direct SMS services. In addition, when interacting with our customer service, you may be required to provide us with more personal data to provide quality services to you.</p>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">3.0 Policy statement</h5>
                        <h6 className="font-medium my-1">3.1	Who We Are</h6>
                        <p className="mb-2.5">CreditChek Technologies Nigeria Limited (CreditChek) uses structured, user-permissioned data from multiple sources to create powerful credit and financial data verification services that empower FinTech’s, banks, microfinance institutions, retail businesses and mobile money operators to build better financial products for the underserved. CreditChek is well positioned to provide the much-needed solutions that will drive financial inclusion across various sectors and industries in the region, and beyond.</p>
                        <h6 className="font-medium my-1">3.2 Why do we need the data? </h6>
                        <p className="mb-2.5">CreditChek ensures that the personal data collected and processed is necessary for the purpose of collection, and CreditChek shall not collect or process more data than is reasonably required for a particular processing activity. In addition, every processing purpose has at least one lawful basis for processing to safeguard the rights of the data subjects.</p>
                        <h6 className="font-medium my-1">3.3 Sharing Your Personal Data</h6>
                        <p className="mb-2.5">We may share your data with our partners or suppliers to provide services to you. Such data may include your name, phone number, email address, and other general related data. We may also share your data with companies or organisations that we engage to provide services to us for the furtherance of your transaction or direct you to a third-party website that may provide services for you directly and/or on our behalf. An example is the funding of your wallet stage.</p>
                        <p className="mb-2.5">THE PRIVACY POLICIES OF THESE THIRD-PARTY SITES MAY BE DIFFERENT FROM OUR DATA PROTECTION AND PRIVACY POLICY. THUS, WILL GOVERN ANY TRANSACTIONS AND SERVICES PERFORMED ON THAT SITE. CREDITCHEK AND SUCH COMPANIES ARE EACH INDEPENDENT DATA CONTROLLERS AND NOT JOINT DATA CONTROLLERS. Hence, you are advised to read and consent to such third-party site data protection and privacy policy.</p>
                        <p className="mb-2.5">Notwithstanding the provision of this clause, Creditchek does not sell or trade customer personal data to anyone. We only use it to render our services to you.</p>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">4.0	Consent</h5>
                        <p className="mb-2.5">CreditChek requires your explicit consent to process collected personal data. And by consenting to this privacy policy, you are giving us the permission to use/process your personal data specifically for the purpose identified before collection.</p>
                        <p className="mb-2.5">If, for any reason, CreditChek is requesting sensitive personal data from you, you will be rightly notified why and how the information will be used.</p>
                        <p className="mb-2.5">You may withdraw consent at any time by requesting for Withdrawal of Consent form, following the CreditChek Withdrawal of Consent Procedure.</p>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">5.0 Means of Securing Customer Personal Data</h5>
                        <p className="mb-2.5">We secure your data by using a database with limited access and a secure server that encrypts all the data we collect. Hence protecting it from unauthorised third parties. We as well use multiple security control systems in layers to prevent access to information on our system.</p>
                        <h6 className="font-medium my-1">5.1	Disclosure</h6>
                        <p className="mb-2.5">CreditChek will not pass on your personal data to third parties without first obtaining your consent.</p>
                        <p className="mb-2.5">Where there is a need for a third party to process the personal data of data subjects, CreditChek will enter into a Data Processing Agreement with the third party and be satisfied that the third party has adequate measures in place to protect the data against accidental or unauthorised access, use, disclosure, loss, or destruction.</p>
                        <p className="mb-2.5">In a case where the disclosure is to third parties outside the jurisdiction of the GDPR and NDPR, CreditChek will ensure that the third party meets the core regulatory standards prior to the transfer. This may include transferring the personal data to the third party where CreditChek has satisfied that:</p>
                        <ul className="list-disc ml-[20px]">
                            <li>the country of the recipient has adequate data protection controls established by legal or self-regulatory regime</li>
                            <li>CreditChek has a contract in place that uses existing or approved data protection clauses to ensure adequate protection</li>
                            <li>CreditChek is making the transfer under approved binding corporate rules</li>
                            <li>CreditChek is relying on approved codes of conduct or certification mechanisms, together with binding and enforceable commitments in the foreign country or international organisation to apply the appropriate safeguards in relation to data subject rights</li>
                            <li>Provisions inserted into administrative arrangements between public authorities or bodies authorised by the competent supervisory authority.</li>
                        </ul>
                        <h6 className="font-medium my-1">5.2	Retention of Records</h6>
                        <p className="mb-2.5">In compliance with the GDPR/NDPR data retention policy, CreditChek will process your personal data for about six (6) years and will retain the personal data for ten (10) years after processing. </p>
                        <p className="mb-2.5">This retention period has been established to enable us to use the personal data for the necessary legitimate purposes identified, in full compliance with the legal and regulatory requirements. When we no longer need to use your personal information, we will delete it from our systems and records, and/or take steps to encrypt/anonymise it to protect your identity.</p>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">6.0 Customers Compliance</h5>
                        <p className="mb-2.5">It is the responsibility of you as Creditchek customer to ensure that you comply and continue to comply with Applicable Data Protection Laws and Regulation in your use of our services and in processing personal data. In addition, you will be responsible for the right to transfer, share and receive the consent of data subjects that you share with us to provide our service or usage of our service.</p>
                        <p className="mb-2.5">By using our service, you hereby undersigned and agree as follow:</p>
                        <ul className="list-disc ml-[20px]">
                            <li>You are complying and will continue to comply with applicable Data Protection Laws and Regulations that you processed through your use of our service.</li>
                            <li>Hereby indemnify Creditchek of any and/all liabilities, claims, legal actions, losses, and damages because of data violation and/or you are breaching any data protection laws and regulations.</li>
                            <li>You will not intentionally collect or process any special categories of Personal Data unless Creditchek includes such types of data in the content submitted to you</li>
                            <li>Taking into account the nature of processing and the information available to you, You shall provide Creditchek, insofar as this is possible and at Creditchek’s written request, with all information required for Creditchek to comply with statutory obligations under Applicable Data Protection Law (in particular, the obligations necessary to ensure the Creditchek’s compliance with security of processing, personal data breach notification, data protection impact assessment, and prior consultations with supervisory authorities), and will assist Creditchek, insofar as reasonable, in meeting these statutory obligations.</li>
                        </ul>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">7.0 Customers Choices and Rights</h5>
                        <h6 className="font-medium my-1">7.1	Data Subject Rights</h6>
                        <p className="mb-2.5">Data subjects, according to the provision of the GDPR/NDPR, have certain rights. At any point while CreditChek are in possession of or processing your personal data, you, the data subject, have the right to:</p>
                        <ul className="list-disc ml-[20px]">
                            <li>Request a copy of the information that we hold about you</li>
                            <li>Correct the data that we hold about you that is inaccurate or incomplete</li>
                            <li>Ask for the data we hold about you to be erased from our systems/record</li>
                            <li>Restrict processing of your personal data where certain conditions apply</li>
                            <li>Have the data we hold about you transferred to another organisation</li>
                            <li>Object to certain types of processing like direct marketing</li>
                            <li>Object to automated processing like profiling, as well as the right to be subject to the legal effects of automated processing or profiling</li>
                            <li>Judicial review. If CreditChek refuses your request under rights of access, we will provide you with a reason as to why. And you have the right to complain as outlined in clause 3.6 below.</li>
                        </ul>
                        <p className="mb-2.5">All the above requests will be forwarded should there be a third party involved in the processing of your personal data.</p>
                        <p className="mb-2.5">You have the reserved right to decline the request of providing your personal information when requested by Creditchek or Creditchek’s authorised representatives. You also have the right to withdraw given consent to our data protection policy and privacy policy Please contact us at dataprotection@creditchek.africa if you wish to exercise any of your rights. It is however noteworthy to mention that certain services or all services may be unavailable to you afterward.</p>
                        <h5 className="font-semibold mb-1 mt-2.5 text-base">8.0 Privacy statement</h5>
                        <p className="mb-2.5">For more information on how we use your personal data and why, please visit  <span className="cursor-pointer text-cc-primary">https://creditchek.africa/privacy-policy</span></p>
                    </div>
                </div>
                <ModalFooter>
                    <div className="ml-auto w-[110px]">
                        <Button
                                isValid={true}
                                isLoading={policyLoading}
                                type="button"
                                text="I Accept"
                                className="bg-cc-primary"
                                onClick={()=>acceptPolicy()}
                        />
                    </div>
                </ModalFooter>
            </Modal>
            {
                pendingStatus!=="pending" ?
                <Routes>
                    <Route path="/" element={<Main/>}/>
                    <Route path="/wallet" element={<Wallet />} />
                    <Route path="/wallet/transactions" element={<Transactions />} />
                    <Route path="/wallet/invoices" element={<Invoices />} />
                    <Route path="/wallet/invoices/:id" element={<InvoiceDetails />} />
                </Routes>
                :
                <div>
                    <div className="max-w-[600px] mt-[40px] mx-auto mb-[30px] block text-center">
                        <img src={stamp} alt="empty" className="block m-auto w-[250px]" />
                        <h4 className="my-[15px]">We are Evaluating your profile</h4>
                        <p className='mb-[20px]'>Please wait while we approve your Business profile</p>
                        <div className="mx-auto block w-[150px]">
                            <Button
                                isValid={true}
                                isLoading={false}
                                type="button"
                                text="Send Reminder"
                                className="bg-cc-primary"
                                onClick={()=>doReminder()}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Home;