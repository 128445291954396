import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";

const timeout = 30000;

export const FloatService = {
    newRequest: (payload) => {
        return APIKit(timeout).post(`/float/new-request`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatAgreement: (id) => {
        return APIKit(timeout).post(`/float/accept-conditions/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatRequestReminder: (id) => {
        return APIKit(timeout).post(`/float/request-reminder/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    acceptFloatOffer: (id, payload) => {
        return APIKit(timeout).post(`/float/accept-request/${id}`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    declineFloatOffer: (id) => {
        return APIKit(timeout).post(`/float/decline-request/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    bvnVerification: (payload) => {
        return APIKit(timeout).post(`/float/verification/bvn`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    bankAccountVerification: (payload) => {
        return APIKit(timeout).post(`/float/verification/bank-account`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    CACVerification: (payload) => {
        return APIKit(timeout).post(`/float/verification/cac`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    accountStatementVerification: (payload) => {
        return APIKit(timeout).post(`/float/account-statement`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatDirectorConsent: (payload) => {
        return APIKit(timeout).post(`/float/director-consent`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatDirectorBVNCheck: (payload) => {
        return APIKit(timeout).post(`/float/director/verify-bvn`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatVerifyConsent: (payload) => {
        return APIKit(timeout).post(`/float/verify-consent`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatStats: (id) => {
        return APIKit(timeout).get(`/float/dashboard-stat/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatHistory: (id) => {
        return APIKit(timeout).get(`/float/dashboard-history/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getBusinessDirectors: (id) => {
        return APIKit(timeout).get(`/float/business/${id}/details`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    floatInitiateRequest: (payload) => {
        return APIKit(timeout).post(`/float/initiate-request`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getFloatById: (id) => {
        return APIKit(timeout).get(`/float/get-float/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    repaymentStats: (id) => {
        return APIKit(timeout).get(`float/repayment-stat/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    repaymentHistory: (id) => {
        return APIKit(timeout).get(`/float/repayment-history/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    repaymentHistoryById: (id) => {
        return APIKit(timeout).get(`/float/repayment-history-id/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    paymentHistory: (id) => {
        return APIKit(timeout).get(`/float/payment-history/${id}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    floatBilling: (payload) => {
        return APIKit(timeout).post(`/billing/float-repayment`, payload,{
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
    
    checkPendingFloat: (id) => {
        return APIKit(timeout).get(`/float/check-pending-request/${id}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
    
    getNotifications: (id) => {
        return APIKit(timeout).get(`/float/get-notification/${id}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
    
    toggleNotifications: (id) => {
        return APIKit(timeout).put(`/float/notification/status/${id}`, {}, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },

    getFloatPatchReq: (id) => {
        return APIKit(timeout).get(`/float/patch/${id}`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
    
    floatPatchRequest: (payload) => {
        return APIKit(timeout).post(`/float/patch/request`, payload,{
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },

    getFloatPatchStatuses: (businessId) => {
        return APIKit(timeout).get(`/float/patch/${businessId}/business`, {
            headers: {
                Authorization: `Bearer ${getPublicKey()}`
            }
        })
    },
}