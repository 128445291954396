import {Routes, Route, Navigate} from "react-router-dom";

import CustomerKYC from "./CustomerKYC";
import PdfForm from "./LinkAccount/PdfForm";
import LinkAccIndex from "./LinkAccount";
import EDocSuccess from "./EDocSuccess";
import DirectDebit from "./direct_debit";
import OpenBanking from "./LinkAccount/OpenBanking";

export default function Customers () {
    return(
        <div className="bg-cc-blue-11 min-h-[100vh] py-[80px]">
            <Routes>
                <Route path="/" element={<Navigate to="/customer/onboarding" />}/>
                <Route path="/onboarding" element={<CustomerKYC />}/>
                <Route path="/share-data" element={<LinkAccIndex />}/>
                <Route path="/share-data/upload-pdf" element={<PdfForm />}/>
                <Route path="/share-data/open-banking" element={<OpenBanking />}/>
                <Route path="/edoc-success" element={<EDocSuccess />}/>
                <Route path="/direct-debit/*" element={<DirectDebit />}/>
            </Routes>
        </div>
    )
}