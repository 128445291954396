import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";


export const IncomeService = {
    getLinkedAcccounts: (payload) => {
        return APIKit(15000).post(`/income/business-accounts`, payload,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getUserAcccounts: (payload) => {
        return APIKit(15000).post(`/income/user-accounts`, payload,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    rerunInsight: (payload) => {
        return APIKit(15000).post(`/income/redo-insight`, payload,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    doAccUnlink: (payload) => {
        return APIKit(15000).delete(`/income/remove/linked-account`,
        {
            headers: {
                token: `${getPublicKey()}`
            },
            data: payload 
        })
    },

    encryptText: async (text) => {
        const data = await APIKit(15000).post(`/income/encrypt-data`, text,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
        return data.data.data;
    },

    decryptText: async (text) => {
        const data = await APIKit(15000).post(`/income/decrypt-data`, text,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
        return data.data.data;
    },

    uploadBankStatement: (payload) => {
        return APIKit(60000).post(`/income/pdf-upload`, payload, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getDashboardStats: (id, time) => {
        return APIKit(15000).get(`/income/business/${id}/dashboard-stats?filter=${time}`, {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    monoTransactions: (payload) => {
        return APIKit(15000).post(`/income/transactions`, payload,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getIncomeData: (payload) => {
        return APIKit(90000).post(`/income/data`, payload,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },

    getIncomeInsightData: (payload) => {
        return APIKit(15000).get(`/income/insight-data/${payload}`,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        } )
    },

    retryInsight: (payload) => {
        return APIKit(15000).post(`/income/retry-insight`, payload,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        } )
    },

    getChartData: (payload) => {
        return APIKit(15000).post(`/income/account/graph`, payload,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    getLinkedBorrowers: (payload) => {
        return APIKit(30000).post(`income/linked-account`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getFilter: (id) => {
        return APIKit(15000).get(`/income/fetch/filter-settings/${id}`,
        {
            headers: {
                token: `${getPublicKey()}`
            }
        })
    },
    
    createFilter: (payload, id) => {
        return APIKit(15000).post(`/income/create/filter-settings/${id}`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    doFilterSearch: (payload, id) => {
        return APIKit(15000).post(`/income/automated/filter/${id}`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },

    addKeyword: (payload) => {
        return APIKit(15000).post(`/income/dbr-keyword`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    getKeywords: (businessId) => {
        return APIKit(15000).get(`/income/dbr-keyword/${businessId}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    deleteKeywords: (payload, businessId) => {
        return APIKit(15000).delete(`/income/dbr-keyword/${businessId}`,
            {
                headers: {
                    token: `${getPublicKey()}`
                },
                data: payload
            }
        )
    },
    
    changeKeywordOption: (payload, businessId) => {
        return APIKit(15000).put(`/income/dbr-keyword/${businessId}/change-option`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    initializeConsent: (payload) => {
        return APIKit(15000).post(`/income/open-banking/intialize/consent`, payload,
            {
                headers: {
                    token: `${getPublicKey()}`
                }
            }
        )
    },
    
    successConsent: (payload, token) => {
        return APIKit(15000).post(`/income/open-banking/success/consent`, payload,
            {
                headers: {
                    token: `${token}`
                }
            }
        )
    },

    getOpenBankingBankList: () => {
        return APIKit(15000).get(`/income/open-banking/banks`,
            {
                baseURL: process.env.REACT_APP_Production_Cluster,
                // headers: {
                //     token: `${getPublicKey()}`
                // }
            }
        )
    },

    openBankingInitializeConsent: (payload) => {
        return APIKit(15000).post(`/income/open-banking/intialize/api-consent`, payload,
            {
                baseURL: process.env.REACT_APP_Production_Cluster,
            }
        )
    },

    openBankingGetTransactions: (payload) => {
        return APIKit(15000).post(`/income/open-banking/transactions`, payload,
            {
                baseURL: process.env.REACT_APP_Production_Cluster,
            }
        )
    },
}