import clsx from "clsx";
import Table from "components/Table";
import Button from "components/shared/Button";
import { useState, useEffect } from "react";
import { dateFormatter, handleRequestErrors } from "utils/helpers";
import dropdown from "images/svg/table_dropdown.svg";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { RecovaService } from "services/recova.service";
import ModalLayout from "./layout/ModalLayout";
import { FormikProvider, useFormik } from "formik";
import { TextField } from "components/Form/TextField";
import { TextArea } from "components/Form/TextArea";
import SuccessModal from "./Payouts/components/SuccessModal";
import { toast } from "react-toastify";
import { createProductRecova } from "utils/validationSchemas";
import ConfirmAction from "components/Modal/ConfirmAction";
import RecovaHeaderLayout from "./layout/RecovaHeaderLayout";
import { BsTrash } from "react-icons/bs";
import { LiaSave } from "react-icons/lia";


export default function Products () {
    const [viewData, setViewData] = useState(null)
    const handleChange = () => {

    }
    const [isLoading, setIsLoading] = useState(false)
    const [showAdd, setShowAdd] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [addLoading, setAddLoading] = useState(false)
    const [data, setData] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const form = useFormik({
        initialValues: {
            name: "",
            description: ""
        },
        validationSchema: createProductRecova, 
        onSubmit: (values) => {
            console.log(values)
            setAddLoading(true)
            const payload = JSON.stringify({
                name: values.name,
                description: values.description
            })
            RecovaService.createProduct(payload)
                .then(response=> response.data)
                .then(res => {
                    setAddLoading(false)
                    if(res.status){
                        setShowSuccess(true)
                        form.resetForm()
                        getRecovaProducts()
                    } else {
                        toast.error(res.message)
                    }
                })
                .catch((err) => {
                    handleRequestErrors(err, true)
                    setAddLoading(false)
                });
        }
    })

    useEffect(()=>{
        getRecovaProducts()
    }, [])

    const getRecovaProducts = () => {
        setIsLoading(true)
        RecovaService.getProducts()
            .then(response=> response.data)
            .then(res => {
                setIsLoading(false)
                if(res.status){
                    setData(res.data)
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
                setIsLoading(false)
            });
    }

    const closeCheckList = () => {
        setShowAdd(false)
        setShowSuccess(false)
        form.resetForm()
    }

    return (
        <div>
            <RecovaHeaderLayout>
                <div className="flex justify-between box-border">
                    <p className="text-2xl font-bold">Products</p>
                    <div>
                        <Button
                            isValid={true}
                            isLoading={false}
                            type="button"
                            text="Add New Product"
                            className="bg-cc-primary !border-[1px] border-cc-primary !px-6"
                            onClick={()=>{
                                setShowAdd(true)
                            }}
                        />
                    </div>
                </div>
                <Table
                    tableTitle={"Products"}
                    searchPlaceHolder={"Search Product name ..."}
                    onSearchChange={handleChange}
                    data={data}
                    loading={isLoading}
                    hideSearch={false}
                    trClassName="relative"
                    tableClassName={
                        !!viewData ? "bg-[#F9FAFB] mb-[100px]"
                        : "bg-[#F9FAFB]"
                    }
                    rows={[
                        {
                            header:'Product Name',
                            view: (item) => (
                                <span className="capitalize py-[10px] pl-[10px] pr-[5px]">
                                    {item.name}
                                </span>
                            ),
                        },
                        {
                            header:'Product ID',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {item._id}
                                </span>
                            ),
                        },
                        {
                            header:'No of Mandates',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">{item?.mandateCount||0}</span>
                            ),
                        },
                        {
                            header:'Date Created',
                            view: (item) => (
                                <span className="py-[10px] pl-[10px] pr-[5px]">
                                    {`${dateFormatter(item?.createdAt, 'dd/mm/yyyy')}`}
                                </span>
                            ),
                        },
                        {
                            header:'',
                            view: (item) => (
                                <div className="py-[10px] pl-[10px] pr-[5px]">
                                    <img 
                                        src={dropdown} 
                                        alt="dropdown" 
                                        className="w-[35px] cursor-pointer" 
                                        onClick={()=>{
                                            if(!!viewData){
                                                setViewData(null)
                                            } else {
                                                setViewData(item)
                                            }
                                        }} 
                                    />
                                    {
                                        viewData===item &&
                                        <div 
                                            className={clsx(
                                                "absolute top-[60px] bg-white z-10 w-[calc(100%-40px)] left-[20px] shadow-md border-[1px] border-cc-primary rounded p-[20px]",
                                            )}
                                        >
                                            <MoreDataContainer 
                                                item={item}
                                                setShowDeleteModal={()=>setShowDeleteModal(true)} 
                                            />
                                        </div>
                                    }
                                </div>
                            ),
                        },
                    ]}
                />
                <ModalLayout
                    show={showAdd}
                    handleClose={()=>{
                        closeCheckList()
                    }}
                >
                    <div className="box-border my-10">
                        {
                            !showSuccess &&
                            <div>
                                <p className="text-[24px] font-bold">Add New Product</p>
                                <p className="mb-8">Ensure all fields are correctly filled to continue</p>
                            </div>
                        }
                        {
                            !showSuccess ?
                            <FormikProvider value={form}>
                                <form onSubmit={form.handleSubmit}>
                                    <TextField
                                        label="Product Name"
                                        name="name"
                                        type='text'
                                        placeholder="Input Product Name"
                                        required
                                    />
                                    <TextArea
                                        label="Product Description"
                                        name="description"
                                        type='text'
                                        placeholder="Input Product Description"
                                    />
                                    <Button
                                        isValid={true}
                                        isLoading={addLoading}
                                        type="submit"
                                        text={"Add Product"}
                                        className="bg-cc-primary"
                                    />
                                </form>
                            </FormikProvider>
                            :
                            <SuccessModal
                                title={`Product Added successfully!`}
                                message={`Your product ${form.values.name}, has been added to your list`}
                                onClose={()=>{
                                    closeCheckList()
                                }}
                            />
                        }
                    </div>
                </ModalLayout>
                <ConfirmAction
                    show={showDeleteModal}
                    onCancel={()=>setShowDeleteModal(false)}
                    title={true ? "Note":"Delete Product"}
                    hideActionBtn={true ? true:false}
                    cancelBtnClass={clsx(
                        true ? "w-full":"w-1/2",
                        "!border-[1px] border-[#D0D5DD] bg-white !text-[#344054]"
                    )}
                    actionBtnClass="w-1/2 !bg-[#D92D20]"
                    actionText="Delete"
                    content={
                        true ? 
                        "This product can’t be deleted as it has a mandate attached to it already. "
                        :
                        "Are you sure you want to delete this product? This action cannot be reversed. "
                    }
                    onAction={()=>{}}
                />
            </RecovaHeaderLayout>
        </div>
    )
}

const MoreDataContainer = ({item, setShowDeleteModal}) => {
    const [editDesc, setEditDesc] = useState(false);
    const [prodDesc, setProdDesc] = useState(item.description||"")

    const editProductDesc = () => {
        const payload = JSON.stringify({
            name: item.name,
            description: prodDesc,
            productId: item._id,
        })
        RecovaService.editProduct(payload)
            .then(response=> response.data)
            .then(res => {
                if(res.status){
                    toast.success("Product description updated!")
                } else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                handleRequestErrors(err, true)
            });
    }
 
    return(
        <div>
            <div className="grid grid-cols-5 gap-[20px]">
                <div className="col-span-2 text-sm flex gap-6">
                    <div>
                        <p className="font-bold">Product Description</p>
                        {
                            editDesc ?
                            <textarea
                                className="w-full outline-none"
                                defaultValue={prodDesc}
                                onChange={(e)=>setProdDesc(e.target.value)}
                            />
                            :
                            <p className="!normal-case">{prodDesc||"Not available"}</p>
                        }
                    </div>
                    <div>
                        {
                            !editDesc ?
                            <>
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="button"
                                    text="Edit"
                                    className="bg-white mb-1.5 !border-[1px] border-[#D0D5DD] !text-cc-primary !text-[12px] !px-2 !py-1 !w-[60px] !h-6"
                                    onClick={()=>{
                                        setEditDesc(true)
                                    }}
                                    icon={<HiOutlinePencilAlt className="w-[20px]" />}
                                    iconPosition="right"
                                />
                                <Button
                                    isValid={true}
                                    isLoading={false}
                                    type="button"
                                    text="Delete"
                                    className="bg-white !border-[1px] border-[#D0D5DD] !text-[#DE213C] !text-[12px] !px-2 !py-1 !w-[70px] !h-6"
                                    onClick={()=>{
                                        setEditDesc(true)
                                        setProdDesc("")
                                    }}
                                    icon={<BsTrash className="w-[20px]" />}
                                    iconPosition="right"
                                />
                            </>
                            :
                            <Button
                                isValid={true}
                                isLoading={false}
                                type="button"
                                text="Save"
                                className="bg-white mb-1.5 !border-[1px] border-[#D0D5DD] !text-[#54BD95] !text-[12px] !px-2 !py-1 !w-[65px] !h-6"
                                onClick={()=>{
                                    setEditDesc(false)
                                    editProductDesc()
                                }}
                                icon={<LiaSave className="w-[20px] scale-[1.2]" />}
                                iconPosition="right"
                            />
                        }
                    </div>
                </div>
                <div className="text-sm overflow-hidden">
                    <p className="font-bold">Product ID</p>
                    <p>{item._id}</p>
                </div>
                <div className="text-sm">
                    <p className="font-bold">Date Created</p>
                    <p>{dateFormatter(item?.createdAt, 'dd/mm/yyyy')}</p>
                </div>
                <div className="text-sm">
                    <p className="font-bold">Mandate Period</p>
                    <p>Not available</p>
                </div>
            </div>
            <div className="mt-10 flex justify-end">
                <Button
                    isValid={true}
                    isLoading={false}
                    type="button"
                    text="Delete Product"
                    fullwidth={false}
                    className="bg-white !border-[1px] border-cc-primary !text-[#727272] !text-sm !px-4 !py-1.5"
                    onClick={()=>{
                        setShowDeleteModal()
                    }}
                />
            </div>
        </div>
    )
}