import { useState, useRef, useEffect } from 'react';
import { toast } from "react-toastify";
import { BankService } from "services/bank.service";
import { IncomeService } from "services/income.service";
import { IdentityService } from "services/identity.service";
import { logToBin } from "lib/logs";
import SelectInput from "components/Form/SelectInput";
import { useNavigate, useSearchParams } from "react-router-dom";

import uploadPdf from 'images/svg/upload-pdf.svg';
import uploadComplete from 'images/svg/upload-complete.svg';
// import search from 'images/svg/search.svg';
import cloud from 'images/svg/cloud.svg';
import loader from "images/gif/white-loader.gif";
import notvisible from "images/svg/not-visible.svg";
import isvisible from "images/svg/visible.svg";


function PdfForm () {
    const [searchParams] = useSearchParams();
    const drop = useRef(null);
    let history = useNavigate();
    const [successful, setSuccessful] = useState(false);
    const [option, setOption] = useState("savings");
    const [fileName, setFileName] = useState("");
    const [uploaded, setUploaded] = useState(null);
    const [bankName, setBankName] = useState(null);
    const [password, setPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [visible, setVisible] = useState(false)
    const [accNo, setAccNo] = useState(false);
    const [accName, setAccName] = useState("");
    const [searchTerm, setSearchTerm] = useState('');
    const [ngBanks, setNgBanks] = useState([]);
    const [displayBanks, setDisplayBanks] = useState([]);
    const [accountType, setAccountType] = useState('')

    useEffect(()=>{
        BankService.getAllBankLogo()
            .then(resp=>{
                setNgBanks(resp.data);
                setDisplayBanks(resp.data);
                console.log(resp.data)
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        const filteredData = ngBanks.filter((item) => item.name.toLowerCase().includes(searchTerm.toLowerCase()));
        setDisplayBanks(filteredData)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm, option])

    useEffect(() => {
        drop?.current?.addEventListener('dragover', handleDragOver);
        drop?.current?.addEventListener('drop', handleDrop);
      
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('dragover', handleDragOver);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            drop?.current?.removeEventListener('drop', handleDrop);
        };
    });
    
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const {files} = e.dataTransfer;
      
        if (files && files.length===1) {
            if(files[0].type==="application/pdf"){
                setFileName(files[0].name)
                setUploaded(files[0])
            } else {
                toast.error("Only PDF files are allowed!")
            }
        } else {
            toast.error("You can't upload more than one file!")
        }
    };

    const doUpload = (e) => {
        e.preventDefault();
        if(!/^[a-z][a-z\s]*$/i.test(accName)){
            toast.error('Account name field takes only letters!');
            document.getElementById("accName").style.borderColor = "#FDA29B";
        } else {
            if(accNo.length<10 || accNo.length>10){
                toast.error('Account number must be 10 characters long!');
                document.getElementById("accNo").style.borderColor = "#FDA29B";
            } else {
                if(successful){
                    setSuccessful(false)
                } else {
                    if(bankName===null){
                        toast.error("Please select a bank!")
                        document.getElementById("bankName").style.borderColor = "#FDA29B";
                    } else {
                        if(uploaded===null){
                            toast.error("Please upload a PDF statement!")
                        } else {
                            setIsLoading(true)

                            // change some slugs to what backend expects
                            let newslug = option==='corporate' ? (
                                    bankName.slug==="access-bank"?"accessbank"
                                    : bankName.slug==="fidelity-bank"?"fidelitybank"
                                    : bankName.slug==="first-bank-of-nigeria"?"fbn"
                                    : bankName.slug==="first-city-monument-bank"?"fcmb2"
                                    : bankName.slug==="providus-bank"?"providusbank"
                                    : bankName.slug==="standard-chartered-bank"?"scb"
                                    : bankName.slug==="union-bank-of-nigeria"?"unionbank"
                                    : bankName.slug==="zenith-bank"?"zenithbank"
                                    : bankName.slug
                                )
                                :
                                (
                                    bankName.slug==="access-bank"?"accessbank"
                                    : bankName.slug==="fidelity-bank"?"fidelitybank"
                                    : bankName.slug==="first-bank-of-nigeria"?"fbn"
                                    : bankName.slug==="first-city-monument-bank"?"fcmb"
                                    : bankName.slug==="guaranty-trust-bank"?"gtbank"
                                    : bankName.slug==="stanbic-ibtc-bank"?"stanbicibtc"
                                    : bankName.slug==="sterling-bank"?"sterlingbank"
                                    : bankName.slug==="united-bank-for-africa"?"uba"
                                    : bankName.slug==="zenith-bank"?"zenithbank"
                                    : bankName.slug 
                                )
                        
                            let newOption = (
                                    bankName.slug==="palmpay" ||
                                    bankName.slug==="paga" ||
                                    bankName.slug==="kuda-bank" ||
                                    bankName.slug==="paycom"
                                ) ? "mono" : option

                            let data = new FormData();
                            data.append('borrowerId', searchParams.get("borrowerId"));
                            data.append('bankCode', bankName.code);
                            data.append('appId', searchParams.get("app_id"));
                            data.append('accountNumber', accNo);
                            data.append('accountName', accName);
                            data.append('accountType', newOption);
                            if (password!=="") {
                                data.append('password', password);
                            }
                            if(searchParams.get("type")==='long'){
                                data.append('bankName', bankName.name);
                            }else{
                                data.append('bankName', newslug);
                            }
                            data.append('statement', uploaded);

                            console.log(newslug)

                            if(searchParams.get("type")==='long'){
                                IdentityService.uploadBorrowerStatement(data)
                                    .then((response) => response.data)
                                    .then((res) => {
                                        setIsLoading(false)
                                        if(res.status===true){
                                            setSuccessful(true)
                                            setBankName(null)
                                            setFileName("")
                                            setPassword("")
                                            setUploaded(null)
                                            toast.success(res.message)
                                        } else {
                                            toast.error(res.message)
                                        }
                                    })
                                    .catch((err) => {
                                        setIsLoading(false)
                                        if(err.response){
                                            logToBin(err.response.data, window.location.href);
                                            toast.error(err.response.data.message)
                                        } else{
                                            if(err.message === "timeout of 60000ms exceeded"){
                                                toast.error('Request timed out, please try again!')
                                            } else if (err.message==="Network Error"){
                                                toast.error('Network error, please make sure you are connected to the internet!')
                                            } else{
                                                toast.error("Your request can't be processed at this time, please try again later!");
                                            }
                                        }
                                    });
                            } else {
                                IncomeService.uploadBankStatement(data)
                                    .then((response) => response.data)
                                    .then((res) => {
                                        setIsLoading(false)
                                        if(res.status==="success"){
                                            setSuccessful(true)
                                            setBankName(null)
                                            setFileName("")
                                            setPassword("")
                                            setUploaded(null)
                                            toast.success(res.message)
                                        } else {
                                            toast.error(res.message)
                                        }
                                    })
                                    .catch((err) => {
                                        setIsLoading(false)
                                        if(err.response){
                                            logToBin(err.response.data, window.location.href);
                                            // toast.error("Please make sure the right pdf for your bank is uploaded!")
                                            toast.error(err.response.data.message)
                                        } else{
                                            if(err.message === "timeout of 60000ms exceeded"){
                                                toast.error('Request timed out, please try again!')
                                            } else if (err.message==="Network Error"){
                                                toast.error('Network error, please make sure you are connected to the internet!')
                                            } else{
                                                toast.error("Your request can't be processed at this time, please try again later!");
                                            }
                                        }
                                    });
                            }
                        }
                    }
                }
            }
        }
    }

    const doBack = () => {
        if(successful){
            handleClose()
        } else {
            history(-1)
        }
    }

    const handleAccNo = (e) => {
        setAccNo(e.target.value)
        document.getElementById("accNo").style.borderColor = "#C4C4C4";
    }

    const handleAccName = (e) => {
        setAccName(e.target.value)
        document.getElementById("accName").style.borderColor = "#C4C4C4";
    }

    const handleBankName = (e) => {
        setSearchTerm(e.target.value)
        document.getElementById("bankName").style.borderColor = "#C4C4C4";
    }

    const handleClose = () => {
        window.open('about:blank', '_self');
        window.close()
    }

    return(
        <div className="max-w-[500px] block mx-auto bg-white border border-cc-primary rounded-[10px]" >
            <form onSubmit={doUpload} className="m-[20px]" id="myForm" autoComplete="off">
                <div className="myModal_main-widget-shareLeft" >
                    <img src={successful?uploadComplete:uploadPdf} alt="icon" />
                    <h4>{successful?"Statement Upload Succesful":"Upload Bank Statement"}</h4>
                    <p>{successful?"Would you like to upload another bank statement":"Upload and attach your bank account statement."}</p>
                </div>
                {!successful &&
                    <div style={{overflow: "hidden"}}>
                        <div className={`myModal_main-widget-form mt-4 ${accountType==="Individual"?'mb-0':'mb-4'}`}>
                            <label style={{fontWeight:"600"}}>Account Type</label>
                            <SelectInput
                                placeholder={'Select Account PDF Type'}
                                options={['Individual Account', 'Corporate Account', "Mono PDF"]}
                                selectChange={(item)=>{
                                    setAccountType(item);
                                    item==='Corporate Account'?setOption('corporate') : item==='Mono PDF'?setOption('mono') : setOption('savings')
                                    
                                }}
                            />
                        </div>
                        <div className="myModal_main-widget-form mt-0">
                            {
                                accountType==="Individual" &&
                                <div className="type">
                                    <p onClick={()=>setOption("savings")} className={option==="savings"?"type_active":""}>Savings</p>
                                    <p onClick={()=>setOption("current")} className={option==="current"?"type_active":""}>Current</p>
                                </div>
                            }
                            <div className="myModal_main-widget-form" style={{margin:"10px 0 0"}} >
                                <label style={{fontWeight:"600"}}>Choose Bank</label>
                                <input type="text" placeholder="Search for your bank" value={searchTerm} className="cursor-pointer pl-[33px] pr-[15px] py-[5px]" id='bankName' onChange={handleBankName} />
                            </div>
                            <div className="w-full py-[5px] bg-white cursor-pointer border border-solid rounded-[8px]" style={{maxHeight:'300px', overflowY:'scroll'}}>
                                { 
                                    displayBanks<1 ?
                                    <p style={{textAlign:"center"}}>Your bank isn't available for this service yet</p>
                                    :
                                    displayBanks.map((item, index)=>(
                                        <div className="flex items-center px-2.5 py-[5px] hover:bg-cc-blue-1" key={index} onClick={()=>{setBankName(item); setSearchTerm(item?.name);}}>
                                            <img src={item?.logo?.url} alt="" className="w-5 h-5 mr-[5px]"/>
                                            <p className="m-0">{item?.name}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                        <div className="myModal_main-widget-form" style={{marginTop:"-10px"}}>
                            <label style={{fontWeight:"600"}}>Account Name</label>
                            <input type="text" autoComplete="off" required placeholder="Account name" id="accName" onChange={handleAccName} />
                        </div>
                        <div className="myModal_main-widget-form" style={{marginTop:"-20px"}}>
                            <label style={{fontWeight:"600"}}>Account Number</label>
                            <input type="number" required placeholder="Account number" id='accNo' onChange={handleAccNo} />
                        </div>
                        <div className="myModal_main-widget-form" style={{marginTop:"-20px"}}>
                            <label style={{fontWeight:"600"}}>PDF Password(if available)</label>
                            <input type={visible?"text":"password"} placeholder="Password" autoComplete='new-password' onChange={(e)=>setPassword(e.target.value)} />
                            <img src={visible?isvisible:notvisible} alt="" className="eye" onClick={()=>setVisible(!visible)}/>
                        </div>
                        <input type="file" accept="application/pdf" id='input_file' onChange={(e)=>{setUploaded(e.target.files[0]);setFileName(e.target.files[0].name)}} hidden />
                        <div className="myModal_main-widget-form-dragUpload" ref={drop} onClick={()=>document.getElementById('input_file').click()}>
                            <img src={cloud} alt="" />
                            <p><span className="text1">Click to upload</span> or drag and drop</p>
                            <p className="text2">PDF files ONLY &#40;max. 10MB&#41;</p>
                        </div>
                        <p style={{margin:"0 0 30px"}}>{fileName}</p>
                    </div>
                }
                <div className="myModal_main-widget-share-action">
                    <button className="goBack" type='button' onClick={doBack} style={{width:"39%"}}>{successful?"Close Page":"Go Back"}</button>
                    <button className="share" type='submit' style={{marginLeft:"auto", width:"39%"}} disable={isLoading}>{isLoading?<img src={loader} alt="" width="25px" className="block mx-auto" />:successful?'Add Another Statement':"Upload Statement"}</button>
                </div>
            </form>
        </div>
    )
}

export default PdfForm;