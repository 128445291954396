import { APIKit } from "services";
import { getPublicKey } from "utils/helpers";

let isDev = process.env.REACT_APP_NODE_ENV === 'development'
let voltronURL = isDev ? 
    process.env.REACT_APP_Dev_Cluster
    :
    process.env.REACT_APP_LIVE_VOLTRON_URL;
    

export const RecovaService = {
    getAppSetup: () => {
        return APIKit(15000).get(`recova/setup/app`, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    recovaIntent: (payload) => {
        return APIKit(15000).post(`recova/setup/initialize`, payload, {
            headers: {
                token: getPublicKey()
            }
        })
    },
    
    updatePaymentOption: (payload) => {
        return APIKit(15000).post(`recova/setup/payment-option`, payload, {
            headers: {
                token: getPublicKey()
            }
        })
    },
    
    linkAccount: (payload) => {
        return APIKit(15000).post(`recova/setup/link-account`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    enableCreditInsurance: (payload) => {
        return APIKit(15000).post(`recova/setup/credit/insurance`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    creditInsuranceUsecase: (payload) => {
        return APIKit(15000).post(`recova/setup/credit/use-case`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    addLoanProdDefs: (payload) => {
        return APIKit(15000).post(`recova/setup/credit/defination`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    acceptTerms: (payload) => {
        return APIKit(15000).post(`recova/setup/accept-terms`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getRecovaCustomers: () => {
        return APIKit(15000).get(`recova/consent/business/borrowers`, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    createMandate: (payload) => {
        return APIKit(15000).post(`recova/consent/create`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    getConsentedBankList: (payload) => {
        return APIKit(15000).post(`recova/kyc/bank/list`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    verifyVFDConsent: (payload) => {
        return APIKit(15000).post(`recova/kyc/bvn-verify`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    getKycClientData: (payload) => {
        return APIKit(15000).post(`recova/kyc/client-data`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getMandateById: (mandateId) => {
        return APIKit(15000).post(
            `recova/consent/business/get-mandate`, 
            JSON.stringify({ mandateId }), 
            {
                headers: {
                    token: getPublicKey()
                }, 
            }
        )
    },

    payoutSwitchService: (payload) => {
        return APIKit(15000).post(`recova/payout/update-setup`, payload, {
            headers: {
                token: getPublicKey()
            }
        })
    },
    
    payoutUpdateAccount: (payload) => {
        return APIKit(15000).post(`recova/payout/link-account`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getAllMandates: (search, status) => {
        return APIKit(15000).get(`recova/consent/business`, {
            headers: {
                token: getPublicKey()
            }, 
            params: {
                search,
                status: status==="all"?"":status
            }
        })
    },

    microDepositMandate: (payload) => {
        return APIKit(15000).post(`recova/micro-deposit/create/mandate`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    eSignatureMandate: (payload) => {
        return APIKit(15000).post(`recova/mandate/create-mandate`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    getBanksForBackup: (mandateId) => {
        return APIKit(15000).get(`/recova/kyc/mandate/banks/${mandateId}`, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    getProducts: () => {
        return APIKit(15000).get(`/recova/product`, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    createProduct: (payload) => {
        return APIKit(15000).post(`/recova/product/create`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    editProduct: (payload) => {
        return APIKit(15000).post(`/recova/product/edit`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    updateMandateStatus: (payload, type) => { // type = pause/cancel/resume
        return APIKit(15000).post(`/recova/consent/business/${type}-mandate`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getBvnData: (payload) => {
        return APIKit(15000).post(`/recova/kyc/bvn-verify`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getMicrodepositBanklist: () => {
        return APIKit(15000).get(`/recova/micro-deposit/bank-list`, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getCollections: (args) => { // args can be: type, search, status, from, to
        return APIKit(15000).get(`/recova/consent/business/collection`, {
            headers: {
                token: getPublicKey()
            }, 
            params: {
                ...args,
            }
        })
    },

    getPayoutActivities: (startDate, endDate) => {
        return APIKit(15000).get(
            `/payouts/activity`,
            {
                baseURL: voltronURL,
                headers: {
                    token: getPublicKey()
                },
                params: {
                    startDate,
                    endDate,
                }
            }
        )
    },

    getPayoutWallet: () => {
        return APIKit(15000).get(`/payouts/wallet`, {
            baseURL: voltronURL,
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    createWallet: (id) => {
        return APIKit(15000).post(`/wallet/payout/${id}`, {}, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    nameEnquiry: (payload) => {
        return APIKit(15000).post(`/payouts/name-enquiry`, payload, {
            baseURL: voltronURL,
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    requestWithdrawal: (payload) => {
        return APIKit(15000).post(`/payouts/withdrawal-requests`, payload, {
            baseURL: voltronURL,
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    directDebitNameEnquiry: (payload) => {
        return APIKit(15000).post(`/recova/consent/account/bank-name-enquiry`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
    
    toggleBalanceEnquiry: (payload) => {
        return APIKit(15000).post(`/recova/consent/balance-enquiry`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    initiateDebit: (payload) => {
        return APIKit(15000).put(`/recova/consent/business/manual/activate`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getCollectionStats: (type, startDate, endDate) => {
        return APIKit(15000).get(`/recova/consent/business/collection-stats`, {
            headers: {
                token: getPublicKey()
            }, 
            params: {
                type,
                startDate,
                endDate,
            }
        })
    },

    sendMandateLink: (payload) => {
        return APIKit(15000).post(`recova/consent/send-link`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    getPreferences: () => {
        return APIKit(15000).get(`recova/preference/get`, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },

    editPreferences: (payload) => {
        return APIKit(15000).post(`recova/preference/edit`, payload, {
            headers: {
                token: getPublicKey()
            }, 
        })
    },
}