import WidgetSetup from "./components/WidgetSetup";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { WalletService } from "services/wallet.service";
import { logToBin } from "../../../lib/logs";
import { Mixpanel } from "../../../lib/mixpanel";
import { useSelector, useDispatch } from "react-redux";
import { setWallet } from "redux/wallet";
import clsx from "clsx";
import { AuthService } from "services/auth.service";
import SelectApp from "./components/SelectApp";

import play from "../../../images/svg/home/play.svg";
import gear from "../../../images/svg/home/gear.svg";
import docs from "../../../images/svg/home/docs.svg";
import WalletBox from "./components/WalletBox";
import { useNavigate } from "react-router-dom";


function Main () {
    const [showModal, setShowModal] = useState(false);
    const b2b_data = useSelector(state => state?.loginData?.data);
    const dispatch = useDispatch();
    const [widgetAction, setWidgetAction] = useState("Setup");
    const [showSelect, setShowSelect] = useState(false)
    const [selectedAppID, setSelectedAppID] = useState("");
    const navigate = useNavigate()

    useEffect(()=>{
        getAppsLinkedtoWidget()
        WalletService.getWallet()
            .then((response) => response.data)
            .then((res) => {
                if (res.success === true && res.data !== null ){
                    dispatch(setWallet(res.data))
                    if(res?.data?.create_static){
                        navigate('/home/wallet')
                    }
                }else {
                    toast.error(res.message)
                }
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                    // toast.error(err.response.data.error.message);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
            // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    const [widgetApps, setWidgetApps] = useState([])

    const getAppsLinkedtoWidget = () => {
        AuthService.getAppLinkedToWidget(b2b_data.user.businessId)
            .then((response) => response.data)
            .then((res) => {
                setWidgetApps(res.data);
            })
            .catch((err) => {
                if(err.response){
                    logToBin(err.response.data, window.location.href);
                } else{
                    if(err.message === "timeout of 15000ms exceeded"){
                        toast.error('Request timed out, please try again!')
                    } else if (err.message==="Network Error"){
                        toast.error('Network error, please make sure you are connected to the internet!')
                    } else{
                        toast.error("Your request can't be processed at this time, please try again later!");
                    }
                }
            });
    }

    return(
        <div>
            <div className="py-[30px] px-10 md:px-[20px]">
                <div className="flex items-start justify-between gap-6 xl:flex-col">
                    <div>
                        <h5 className="text-[1.2em] font-bold capitalize">Welcome {b2b_data?.businessName}</h5>
                        <p className="text-[1em]">Let’s get you started with CreditChek</p>
                    </div>
                    <div className="sm:w-full">
                        <WalletBox/>
                    </div>
                </div>
                <div className="box-border my-10 grid grid-cols-3 xl:grid-cols-2 md:!grid-cols-1 gap-[30px]">
                    <div className="shadow-md rounded-[10px] text-center h-fit">
                        <div className="min-h-[150px] flex flex-col items-center justify-center p-[20px]">
                            <img src={docs} alt="icon" className="w-[60px]" />
                            <h6 className="font-bold font-mulish font-base">Docs and Guides</h6>
                        </div>
                        <div className="border-b-[0.5px] border-b-[#D0D5DD]" />
                        <div className="min-h-[150px] flex flex-col items-center justify-between pt-[20px] pb-8 px-">
                            <p>Learn about our APIs</p>
                            <a href="https://docs.creditchek.africa/#intro" target="_blank" rel="noreferrer" className="text-black no-underline" >
                                <button 
                                    onClick={()=> Mixpanel.track('Docs & Guides button was clicked', {
                                        name: `${b2b_data?.user?.firstName} ${b2b_data?.user?.lastName}`,
                                        bussinessName: b2b_data?.businessName
                                    })} 
                                    className="bounceup-animate w-[150px] border-[1px] border-cc-primary rounded-[8px] bg-transparent py-2 px-[15px] text-center font-medium"
                                > 
                                    View 
                                </button>
                            </a>
                        </div>
                    </div>
                    <div className="shadow-md rounded-[10px] text-center relative h-fit bg-cc-primary text-white">
                        <div className="absolute top-4 right-4">
                            <div className="flex items-center justify-center mb-1">
                                <p className="text-[10px]">Current State:</p>
                                <div className={clsx(
                                        "rounded-full ml-1 flex items-center text-xs font-medium",
                                        widgetApps?.length===0 ? "border-cc-red-9":"border-cc-green-15"
                                    )}
                                >
                                    <div className={clsx(
                                            "w-1.5 h-1.5 rounded-full mr-0.5",
                                            widgetApps?.length===0 ? "bg-cc-red-9":"bg-cc-green-15"
                                        )}
                                    ></div>
                                    <p 
                                        className={clsx(
                                            "text-[10px]",
                                            widgetApps?.length===0 ? "text-cc-red-9":"text-cc-green-15"
                                        )}
                                    >
                                        {widgetApps?.length===0 ? "Inactive":"Active"}
                                    </p>
                                </div>
                            </div>
                            <div className={clsx(
                                    "flex justify-center",
                                    widgetApps?.length>1 ? "items-start" : "items-center",
                                )}
                            >
                                <p className="text-[10px]">Linked App:</p>
                                {
                                    widgetApps.length===0 ? 
                                    <p className="ml-1 text-[10px]">None</p>
                                    :
                                    <div>
                                        {
                                            widgetApps?.map(item=>
                                                <div className={clsx(
                                                        "rounded-full ml-1 flex items-center text-xs font-medium",
                                                        widgetApps.length>1 && "mb-0.5"
                                                    )}
                                                    key={item._id}
                                                >
                                                    <div className={clsx(
                                                            "w-1.5 h-1.5 bg-cc-green-15 rounded-full mr-0.5",
                                                        )}
                                                    ></div>
                                                    <p className="text-cc-green-15 text-[10px]">{item?.appId?.appName}</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="min-h-[150px] flex flex-col items-center justify-center p-[20px]">
                            <img src={gear} alt="icon" className="w-[50px] bg-white rounded-full p-2.5 mb-2" />
                            <h6 className="font-bold font-mulish font-base">Customized<br/>Widget Builder</h6>
                        </div>
                        <div className="border-b-[0.5px] border-b-[#D0D5DD]" />
                        <div className="min-h-[150px] flex flex-col items-center justify-between pt-[20px] pb-8 px-">
                            <p>{widgetAction} up your CreditChek’s business SDK to customise your Risk engine</p>
                            <button onClick={()=>setShowSelect(true)} className='text-black w-[150px] border-[1px] border-cc-white rounded-[8px] bg-white py-2 px-[15px] text-center font-medium'>{widgetAction} Widget</button>
                        </div>
                    </div>
                    <div className="shadow-md rounded-[10px] text-center h-fit">
                        <div className="min-h-[150px] flex flex-col items-center justify-center p-[20px] relative">
                            <img src={play} alt="icon" className="w-[60px]" />
                            <h6 className="font-bold font-mulish font-base">Live Demo</h6>
                        </div>
                        <div className="border-b-[0.5px] border-b-[#D0D5DD]" />
                        <div className="min-h-[150px] flex flex-col items-center justify-between pt-[20px] pb-8 px-[20px]">
                            <p>See a live Demo of the CreditChek Business App</p>
                            <a href="https://demoloan.creditchek.africa" target="_blank" rel="noreferrer" className="text-black no-underline" >
                                <button className="bounceup-animate w-[150px] border-[1px] border-cc-primary rounded-[8px] bg-transparent py-2 px-[15px] text-center font-medium">Launch Demo</button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <SelectApp
                show={showSelect}
                handleClose={()=>setShowSelect(false)}
                openModal={(item)=>{
                    setShowModal(true); 
                    setShowSelect(false);
                    setSelectedAppID(item.activeApp._id);
                }}
            />
            <WidgetSetup 
                show={showModal} 
                cancel={()=>setShowModal(false)} 
                changeAction={(val)=>setWidgetAction(val)} 
                appId={selectedAppID}
            />
        </div>
    )
}

export default Main;